import { CONSTANTS } from "../config/constants"
import { obtenirValeur } from "../utilitaires/localStorageUtil"

export function rechercherEmplacement(event, state, setState)
{
    if(event.target.value.length > 5)
    {
      const token = obtenirValeur(CONSTANTS.nomValeurToken);

      fetch(CONSTANTS.nomDomaineApi + "/google/rechercher-adresse", {
        headers: {
          'Content-Type': 'application/json',
          'Authorization':'Bearer ' + token
        },
        method: "POST",
        body: JSON.stringify({
          "adresse": event.target.value
        }),
        redirect: 'follow'
      })
        .then(res => res.json())
        .then(
          (res) => {
            if(res.contenu)
            {
              let contenu = res.contenu
              let nouvelListeResultatMap = contenu.map((adresse) => {
                  return {
                      valeur: adresse.formatted_address
                  }
              })
              setState({
                  ...state,
                  evenement: {
                    ...state.evenement,
                    adresse: event.target.value
                  },
                  listeResultatMap: nouvelListeResultatMap
              })
            }
          },
          (err) => {
            console.log(err)
          }
        )
    }
}