import React from 'react'
import Container from 'react-bootstrap/Container';
import { Navbar, Nav, NavDropdown, Col } from 'react-bootstrap';
import styles from './navigation.module.css';
import { Link } from "react-router-dom";
import Row from 'react-bootstrap/Row';
import * as Icon from 'react-bootstrap-icons';
import { obtenirValeur } from '../../utilitaires/localStorageUtil';
import { CONSTANTS } from '../../config/constants';

export default function Navigation() {

    const token = obtenirValeur(CONSTANTS.nomValeurToken)
    const profil = obtenirValeur(CONSTANTS.nomValeurProfil, true)

    return (
        <div>
            {
                (!token || !profil) &&
                <Row className={styles.conteneur_navigation_non_connecte}>
                    <Col xs={3}></Col>
                    <Col xs={6} className="text-center">
                        <Link className={styles.hf_titre_logo} to="/">
                            <Row>
                                <span className={styles.hf_sous_titre_logo_icone}>Gestion</span>
                                <span className={styles.hf_titre_logo_icone}>Emotions</span>
                            </Row>
                        </Link>
                    </Col>
                    <Col xs={3}></Col>
                </Row>
            }
            {
                (token && profil) &&
                <Row>
                    <Navbar className={styles.conteneur_navigation} collapseOnSelect expand="lg">
                        <Container>
                            <Navbar.Brand href="/">
                                <Row>
                                    <span className={styles.hf_sous_titre_logo_icone}>Gestion</span>
                                    <span className={styles.hf_titre_logo_icone}>Emotions</span>
                                </Row>
                            </Navbar.Brand>
                            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                            <Navbar.Collapse id="responsive-navbar-nav">
                                <Nav className="me-auto">
                                    <NavDropdown title="Gestion des événements" id="basic-nav-dropdown">
                                        <Link className="dropdown-item" to="/ajouter-evenement">Ajouter un événement</Link>
                                        <Link className="dropdown-item" to="/voir-tous-les-evenements">Voir tous les événements</Link>
                                        <NavDropdown.Divider />
                                        <Link className="dropdown-item" to="/ajouter-categorie-evenement">Ajouter une catégorie d'événement</Link>
                                        <Link className="dropdown-item" to="/voir-toutes-les-categories-evenement">Voir les catégories d'événement</Link>
                                        <NavDropdown.Divider />
                                        <Link className="dropdown-item" to="/ajouter-categorie-image">Ajouter une catégorie d'image</Link>
                                        <Link className="dropdown-item" to="/voir-toutes-les-categories-images">Voir les catégories d'images</Link>
                                        <NavDropdown.Divider />
                                        <Link className="dropdown-item" to="/bibliotheque-image-evenement">Biblothèque des images des événements</Link>
                                    </NavDropdown>
                                    <NavDropdown title="Gestion des utilisateurs" id="basic-nav-dropdown">
                                        <Link className="dropdown-item" to="/ajouter-utilisateur">Ajouter un utilisateur</Link>
                                        <Link className="dropdown-item" to="/voir-tous-les-utilisateurs">Voir tous les utilisateurs</Link>
                                    </NavDropdown>
                                </Nav>
                                <Nav className="justify-content-end">
                                    <Link 
                                        className={"nav-link " + styles.nav_link_alerte}
                                        to="/alertes"
                                    >
                                        <Icon.ExclamationCircle className={styles.hf_icone_alerte} size={24} color="grey"/>
                                        Alertes
                                    </Link>
                                    <Link to="/deconnexion" className={"nav-link " + styles.nav_link}>
                                        <Icon.BoxArrowInLeft className={styles.hf_icone_deconnexion} size={24} color="grey"/> 
                                        <span className={styles.hf_icone_texte}>Se déconnecter</span>
                                    </Link>
                                </Nav>
                            </Navbar.Collapse>
                        </Container>
                    </Navbar>
                </Row>
            }
        </div>
    );
  }