import React from 'react';
import ReactDOM from 'react-dom/client';
import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";
import './index.css';
import PageConnexion from './pages/page-connexion';
import PageNonTrouve from './pages/page-non-trouve';
import PageDeconnexion from './pages/page-deconnexion';
import PageAjouterEvenement from './pages/page-ajouter-evenement';
import PageVoirEvenement from './pages/page-voir-evenement';
import PageVoirTousLesEvenements from './pages/page-voir-tous-les-evenements';
import PageTableauDeBord from './pages/page-tableau-de-bord';
import PageModifierEvenement from './pages/page-modifier-evenement';
import PageVoirEvenementParCategorie from './pages/page-voir-evenement-par-categorie';
import PageMotDePasseOublier from './pages/page-mot-de-passe-oublier';
import PageBibliothequeImageEvenement from './pages/page-bibliotheque-image-evenement';
import PageAjouterCategorieEvenement from './pages/page-ajouter-categorie-evenement';
import PageVoirToutesLesCategoriesEvenement from './pages/page-voir-toutes-les-categories-evenement';
import PageAjouterCategorieImage from './pages/page-ajouter-categorie-image';
import PageModifierCategorieEvenement from './pages/page-modifier-categorie-evenement';
import PageVoirToutesLesCategoriesImage from './pages/page-voir-toutes-les-categories-images';
import PageModifierCategorieImage from './pages/page-modifier-categorie-image';
import PageAjouterUnUtilisateur from './pages/page-ajouter-un-utilisateur';
import PageVoirTousLesUtilisateurs from './pages/page-voir-tous-les-utilisateurs';
import PageModifierUtilisateur from './pages/page-modifier-utilisateur';
import PageVoirUtilisateur from './pages/page-voir-utilisateur';
import PageAlerte from './pages/page-alerte';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
    <Routes>
      <Route path="/" element={<PageTableauDeBord />} />
      <Route path="/connexion" element={<PageConnexion />} />
      <Route path="/deconnexion" element={<PageDeconnexion />} />
      <Route path="/ajouter-evenement" element={<PageAjouterEvenement />} />
      <Route path="/voir-tous-les-evenements" element={<PageVoirTousLesEvenements />} />
      <Route path="/voir-evenement/:evenement_id" element={<PageVoirEvenement />} />
      <Route path="/modifier-evenement/:evenement_id" element={<PageModifierEvenement />} />
      <Route path="/supprimer-evenement/:evenement_id" element={<PageVoirEvenement />} />
      <Route path="/voir-tous-les-evenement/categorie/:categorie_slug" element={<PageVoirEvenementParCategorie />} />
      <Route path="/mot-de-passe-oublier" element={<PageMotDePasseOublier />} />
      <Route path="/bibliotheque-image-evenement" element={<PageBibliothequeImageEvenement />} />
      <Route path="/bibliotheque-image-evenement/categorie/:categorie_slug" element={<PageBibliothequeImageEvenement />} />
      <Route path="/ajouter-categorie-evenement" element={<PageAjouterCategorieEvenement />} />
      <Route path="/voir-toutes-les-categories-evenement" element={<PageVoirToutesLesCategoriesEvenement />} />
      <Route path="/ajouter-categorie-image" element={<PageAjouterCategorieImage />}/>
      <Route path="/modifier-categorie-evenement/:categorie_slug" element={<PageModifierCategorieEvenement />}/>
      <Route path="/voir-toutes-les-categories-images" element={<PageVoirToutesLesCategoriesImage />} />
      <Route path="/modifier-categorie-image/:categorie_slug" element={<PageModifierCategorieImage />} />
      <Route path="/voir-utilisateur/:utilisateur_id" element={<PageVoirUtilisateur />} />
      <Route path="/ajouter-utilisateur" element={<PageAjouterUnUtilisateur />} />
      <Route path="/modifier-utilisateur/:utilisateur_id" element={<PageModifierUtilisateur />} />
      <Route path="/voir-tous-les-utilisateurs" element={<PageVoirTousLesUtilisateurs />} />
      <Route path="/alertes" element={<PageAlerte />} />
      <Route path="*" element={<PageNonTrouve />} />
    </Routes>
  </BrowserRouter>
);