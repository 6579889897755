import React, { useState } from 'react'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { changer_mon_mot_de_passe, envoyer_code_reinitialisation, envoyer_courriel_reinitialisation } from '../../services/connexionService';
import { Link } from 'react-router-dom';
import styles from "./formulaire-mot-de-passe-oublier.module.css";
import HFAlerte from '../commun/hf-alerte';

export default function FormulaireMotDePasseOublier(props) {

    const [state, setState] = useState({
        erreurReinitialisationMotDePasse: {
            type:"",
            texte:""
        },
        courriel: "",
        code:"",
        motDePasse:"",
        motDePasseRepeter:"",
        courrielReinitialisationEnvoye: false,
        codeReinitialisationEnvoye:false,
        motDePasseModifie:false,
    })

    return (
        <>
            <Row className="text-center">
                <Col lg="4"></Col>
                <Col lg="4">
                    <Link className={styles.bouton_retour_page_connexion} to="/connexion">Retourner à la page de connexion</Link>
                </Col>
                <Col lg="4"></Col>
            </Row>
            
            {
                state.erreurReinitialisationMotDePasse.texte &&
                <Row>
                    <Col lg="4" xs="1"></Col>
                    <Col lg="4" xs="10" className="text-center">
                        <HFAlerte 
                            aucunRow
                            type={state.erreurReinitialisationMotDePasse.type} 
                            texte={state.erreurReinitialisationMotDePasse.texte}
                        />
                    </Col>
                    <Col lg="4" xs="1"></Col>
                </Row>
            }
            {
                !state.motDePasseModifie && !state.courrielReinitialisationEnvoye &&
                <Row className={styles.conteneur_form_reinitialisation}>
                    <Col lg="4" xs="1"></Col>
                    <Col lg="4" xs="10" className="text-center">
                        <Form onSubmit={(event) => { envoyer_courriel_reinitialisation(event, state, setState) }} noValidate>
                            <Form.Group className="mb-3" controlId="hf_courriel">
                                <Form.Label>Votre email*</Form.Label>
                                <Form.Control 
                                    onChange={(event) => { 
                                        setState({
                                            ...state,
                                            courriel: event.target.value,
                                            erreurReinitialisationMotDePasse: {
                                                type:"",
                                                texte:""
                                            }
                                        }) 
                                    }} 
                                    type="email" 
                                    placeholder="exemple@havefunquebec.com" 
                                    autoFocus={true}
                                />
                            </Form.Group>
                            <Button className={styles.bouton_envoyer_courriel_reinitialisation} variant="primary" type="submit">
                                Envoyer le courriel de réinitialisation
                            </Button>
                        </Form>
                    </Col>
                    <Col lg="4" xs="1"></Col>
                </Row>
            }
            {
                !state.motDePasseModifie && state.courrielReinitialisationEnvoye && !state.codeReinitialisationEnvoye &&
                <Row>
                    <Col lg="4" xs="1"></Col>
                    <Col lg="4" xs="10" className="text-center">
                        <Form onSubmit={(event) => { envoyer_code_reinitialisation(event, state, setState) }} noValidate>
                            <Form.Group className="mb-3" controlId="hf_courriel">
                                <Form.Label>Code de réinitialisation*</Form.Label>
                                <Form.Control 
                                    onChange={(event) => { 
                                        setState({
                                            ...state,
                                            code: event.target.value,
                                            erreurReinitialisationMotDePasse: {
                                                type:"",
                                                texte:""
                                            }
                                        }) 
                                    }} 
                                    type="text" 
                                    placeholder="123456" 
                                    autoFocus={true}
                                />
                            </Form.Group>
                            <Button className={styles.bouton_envoyer_courriel_reinitialisation} variant="primary" type="submit">
                                Envoyer le code de réinitialisation
                            </Button>
                        </Form>
                    </Col>
                    <Col lg="4" xs="1"></Col>
                </Row>
            }
            {
                !state.motDePasseModifie && state.codeReinitialisationEnvoye && state.courrielReinitialisationEnvoye &&
                <Row>
                    <Col lg="4" xs="1"></Col>
                    <Col lg="4" xs="10" className="text-center">
                        <Form onSubmit={(event) => { changer_mon_mot_de_passe(event, state, setState) }} noValidate>
                            <Form.Group className="mb-3" controlId="hf_courriel">
                                <Form.Label>Nouveau mot de passe*</Form.Label>
                                <Form.Control 
                                    onChange={(event) => { 
                                        setState({
                                            ...state,
                                            motDePasse: event.target.value,
                                            erreurReinitialisationMotDePasse: {
                                                type:"",
                                                texte:""
                                            }
                                        }) 
                                    }} 
                                    type="password" 
                                    autoFocus={true}
                                />
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="hf_courriel">
                                <Form.Label>Répéter votre nouveau mot de passe*</Form.Label>
                                <Form.Control 
                                    onChange={(event) => { 
                                        setState({
                                            ...state,
                                            motDePasseRepeter: event.target.value,
                                            erreurReinitialisationMotDePasse: {
                                                type:"",
                                                texte:""
                                            }
                                        }) 
                                    }} 
                                    type="password"
                                />
                            </Form.Group>
                            <Button className={styles.bouton_envoyer_courriel_reinitialisation} variant="primary" type="submit">
                                Changer mon mot de passe
                            </Button>
                        </Form>
                    </Col>
                    <Col lg="4" xs="1"></Col>
                </Row>
            }
        </>
    );
  }