import React from 'react'
import { Col, Row, Alert } from 'react-bootstrap';
import styles from "./hf-alerte.module.css";
import * as Icon from 'react-bootstrap-icons';

/**
 * Fonction qui permet de créer une alerte.
 * @param {*} props Prend les paramètres de l'alerte sous forme d'objet JS.
 * Un champ de l'objet doit être appelé 'type' et peut être selon le cas :
 * 'primary' | 'secondary' | 'success' | 'danger' | 'warning' | 'info' | 'dark' | 'light' | string.
 * Un champ de l'objet doit être appelé 'texte' qui contient le contenu de l'alerte.
 * @returns 
 */
export default function HFAlerte(props) {
    return (
        <>
            {
                !props.aucunRow &&
                <Row className={styles.conteneur_hf_alerte}>
                    <Col lg="4" xs="1"></Col>
                    <Col lg="4" xs="10" className="text-center">
                        <Alert className={styles.hf_alerte} variant={props.type}>
                            {
                                props.type === "danger" &&
                                <Icon.BugFill size="24" className={styles.hf_alerte_icone}/>
                            }
                            {
                                props.type === "warning" &&
                                <Icon.ExclamationTriangleFill size="24" className={styles.hf_alerte_icone}/>
                            }
                            {
                                props.type === "success" &&
                                <Icon.CheckLg size="24" className={styles.hf_alerte_icone}/>
                            }
                            <span>{props.texte}</span>
                        </Alert>
                    </Col>
                    <Col lg="4" xs="1"></Col>
                </Row>
            }
            {
                props.aucunRow &&
                <Alert className={styles.hf_alerte + " text-center"} variant={props.type}>
                    {
                        props.type === "danger" &&
                        <Icon.BugFill size="24" className={styles.hf_alerte_icone}/>
                    }
                    {
                        props.type === "warning" &&
                        <Icon.ExclamationTriangleFill size="24" className={styles.hf_alerte_icone}/>
                    }
                    {
                        props.type === "success" &&
                        <Icon.CheckLg size="24" className={styles.hf_alerte_icone}/>
                    }
                    <span>{props.texte}</span>
                </Alert>
            }
        </>
    );
  }