import React from 'react'
import { Col, Row, Spinner } from "react-bootstrap";
import styles from './icone-chargement.module.css';

export default function IconeChargement() {
    return (
        <Row className={styles.conteneur_icone_chargement}>
            <Col className='text-center' xs={12}>
                <Spinner className={styles.spinner_chargement} animation="border" role="status"></Spinner>
            </Col>
        </Row>
    )
}