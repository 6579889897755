import React, { useEffect, useState } from 'react'
import { Row, Col, Form, Button } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import styles from './formulaire-modifier-categorie-evenement.module.css';
import HFAlerte from '../commun/hf-alerte';
import { modifier_categorie_evenement, obtenir_categorie_evenement_par_slug } from '../../services/categorieEvenementService';

export default function FormulaireModifierCategorieEvenement() {

    const [state, setState] = useState({
        alertFormModificationCategorie: {
            type: "",
            texte: ""
        },
        categorie: null
    })
    const params = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        !state.categorie && obtenir_categorie_evenement_par_slug(state, setState, params.categorie_slug)
    }, [state, params.categorie_slug])

    return (
        <>
            <Row className='text-center'>
                <Col lg="4"></Col>
                <Col lg="4">
                    <Button
                        className={styles.bouton_retour_categorie_evenement}
                        onClick={() => {
                            navigate("/voir-toutes-les-categories-evenement");
                        }}
                    >Retour aux catégories d'événement</Button>
                </Col>
                <Col lg="4"></Col>
            </Row>
            {
                state.alertFormModificationCategorie.texte &&
                <HFAlerte 
                    type={state.alertFormModificationCategorie.type}
                    texte={state.alertFormModificationCategorie.texte}
                />
            }
            {
                state.categorie &&
                <Row className={styles.conteneur_form_modification_evenement}>
                    <Col lg="3" xs="1"></Col>
                    <Col lg="6" xs="10" className="text-center">
                        <Form 
                            onSubmit={
                                (event) => {
                                    event.preventDefault();
                                    modifier_categorie_evenement(
                                        state,
                                        setState
                                    ) 
                                }
                            } 
                            noValidate
                        >
                            <Form.Group className="mb-3" controlId="hf_nom_evenement">
                                <Form.Label>Nom de la catégorie*</Form.Label>
                                <Form.Control 
                                    onChange={
                                        (event) => 
                                        { 
                                            setState({
                                                ...state,
                                                alertFormModificationEvenement: {
                                                    type: "",
                                                    texte: ""
                                                },
                                                categorie: {
                                                    ...state.categorie, 
                                                    nom: event.target.value 
                                                }
                                            })
                                        }
                                    } 
                                    type="text" 
                                    placeholder="Festival" 
                                    value={state.categorie.nom} />
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="hf_description_evenement">
                                <Form.Label>Description de l'événement*</Form.Label>
                                <Form.Control
                                    onChange={
                                        (event) => 
                                        { 
                                            setState({ 
                                                ...state,
                                                alertFormModificationEvenement: {
                                                    type: "",
                                                    texte: ""
                                                },
                                                categorie: {
                                                    ...state.categorie,
                                                    description: event.target.value
                                                }
                                            })
                                        }
                                    } 
                                    value={state.categorie.description ?? ""} 
                                    as="textarea" 
                                />
                            </Form.Group>
                            <Button variant="primary" type="submit">
                                Modifier la catégorie
                            </Button>
                        </Form>
                    </Col>
                    <Col lg="3" xs="1"></Col>
                </Row>
            }
        </>
    );
  }