import React, { useEffect } from 'react'
import DocumentMeta from 'react-document-meta';
import Container from 'react-bootstrap/Container';
import Navigation from '../composants/commun/navigation';
import { useState } from 'react';
import TitrePage from '../composants/commun/titre-page';
import PiedPage from '../composants/commun/pied-page';
import { verifier_token } from '../services/connexionService';
import { useLocation, useNavigate } from 'react-router-dom';
import IconeChargement from '../composants/commun/icone-chargement';
import { CONSTANTS } from '../config/constants';
import ListeCategorieEvenement from '../composants/liste-categorie-evenement/liste-categorie-evenement';

export default function PageVoirToutesLesCategoriesEvenement() {
    const [state, setState] = useState({
      enChargement: true,
      meta: {
        title: 'Gestion Emotions | Voir tous les catégories d\'événements'
      }
    });
    const navigate = useNavigate()
    const location = useLocation()
  
    useEffect(() => {
      verifier_token(navigate, location);
      const myInterval = setInterval(verifier_token, CONSTANTS.tempsRequisAvantVerificationCredentiel, navigate, location);
      if(state.enChargement)
      {
        setTimeout(setState, 1000, {
          ...state,
          enChargement: false,
        })
      }
      return () => {
        clearInterval(myInterval)
      }
    }, [navigate, location, state]);

    return (
      <DocumentMeta {...state.meta}>
        <Container fluid>
          <Navigation></Navigation>
          {
            state.enChargement &&
            <>
              <TitrePage titre_page="Vérification de votre identité..."></TitrePage>
              <IconeChargement/>
            </>
          }
          {
            !state.enChargement &&
            <>
              <TitrePage titre_page="Voir tous les catégories d'événements"></TitrePage>
              <ListeCategorieEvenement/>
            </>
          }
          <PiedPage></PiedPage>
        </Container>
      </DocumentMeta>
    );
  }