import React from 'react'
import styles from './titre-page.module.css';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

export default function TitrePage(props) {
    return (
        <Row>
            <Col lg="4" xs="1"></Col>
            <Col lg="4" xs="10" className="text-center">
                <h1 className={ styles.titre_page }>{props.titre_page}</h1>
            </Col>
            <Col lg="4" xs="1"></Col>
        </Row>
    );
  }