import React, { useEffect, useState } from 'react'
import { Row, Col, Form, Button } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import styles from './formulaire-modifier-categorie-image.module.css';
import HFAlerte from '../commun/hf-alerte';
import { modifier_categorie_image, obtenir_categorie_image_par_slug } from '../../services/categorieImageService';

export default function FormulaireModifierCategorieImage() {

    const [state, setState] = useState({
        alertFormModificationImage: {
            type: "",
            texte: ""
        },
        categorieImageCharge:false,
        categorieImageErreur:false,
        categorie: {
            id:"",
            nom: "",
            description: ""
        }
    })
    const params = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        !state.categorieImageCharge && obtenir_categorie_image_par_slug(state, setState, params.categorie_slug)
    }, [state, params.categorie_slug])

    return (
        <>
            {
                state.alertFormModificationImage.texte &&
                <HFAlerte 
                    type={state.alertFormModificationImage.type}
                    texte={state.alertFormModificationImage.texte}
                />
            }
            {
                state.categorieImageCharge && !state.categorieImageErreur &&
                <Row className={styles.conteneur_form_modification_evenement}>
                    <Col lg="3" xs="1"></Col>
                    <Col lg="6" xs="10" className="text-center">
                        <Form 
                            onSubmit={
                                (event) => { 
                                    modifier_categorie_image(
                                        event,
                                        navigate,
                                        state,
                                        setState
                                    ) 
                                }
                            } 
                            noValidate
                        >
                            <Form.Group className="mb-3" controlId="hf_nom_evenement">
                                <Form.Label>Nom de la catégorie*</Form.Label>
                                <Form.Control 
                                    onChange={
                                        (event) => 
                                        { 
                                            setState({
                                                ...state,
                                                alertFormModificationImage: {
                                                    type: "",
                                                    texte: ""
                                                },
                                                categorie: {
                                                    ...state.categorie, 
                                                    nom: event.target.value 
                                                }
                                            })
                                        }
                                    } 
                                    type="text" 
                                    placeholder="Festival" 
                                    value={state.categorie.nom} />
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="hf_description_evenement">
                                <Form.Label>Description de l'événement*</Form.Label>
                                <Form.Control
                                    onChange={
                                        (event) => 
                                        { 
                                            setState({ 
                                                ...state,
                                                alertFormModificationImage: {
                                                    type: "",
                                                    texte: ""
                                                },
                                                categorie: {
                                                    ...state.categorie,
                                                    description: event.target.value
                                                }
                                            })
                                        }
                                    } 
                                    value={state.categorie.description} 
                                    as="textarea" 
                                />
                            </Form.Group>
                            <Button variant="primary" type="submit">
                                Modifier la catégorie
                            </Button>
                        </Form>
                    </Col>
                    <Col lg="3" xs="1"></Col>
                </Row>
            }
        </>
    );
  }