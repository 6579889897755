import React, { useEffect, useState } from 'react'
import Row from 'react-bootstrap/Row';
import styles from './liste-alerte.module.css';
import { obtenir_toutes_les_alertes } from '../../services/alerteService';
import HFAlerte from '../commun/hf-alerte';

export default function ListeAlerte() {
    const [state, setState] = useState({
      listeAlerte: null
    });

    useEffect(() => {
        !state.listeAlerte && obtenir_toutes_les_alertes(state, setState); 
    }, [state])
    
    return (
      <>
        <Row className={'text-center ' + styles.conteneur_alerte}>
              {
                state.listeAlerte &&
                state.listeAlerte.length === 0 &&
                <HFAlerte variant="danger" texte="Aucune alerte à afficher."/>
              }
        </Row>
      </>
    );
  }