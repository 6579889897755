import { CONSTANTS } from "../config/constants";
import { obtenirValeur } from "../utilitaires/localStorageUtil";
import validator from 'validator';

export function obtenir_toutes_les_categories_images(state, setState)
{
    const token = obtenirValeur(CONSTANTS.nomValeurToken);
    fetch(CONSTANTS.nomDomaineApi + "/categories/images", {
      headers: {
        'Content-Type': 'application/json',
        'Authorization':'Bearer ' + token
      },
      method: "GET",
      redirect: 'follow'
    })
      .then(res => res.json())
      .then(
        (res) => {
          if(res.contenu)
          {
            setState({
                ...state,
                listeImageCategorie: res.contenu
            })
            return;
          }
          setState({
              ...state,
              listeImageCategorie: []
          })
        },
        (err) => {
          console.log(err)
          setState({
              ...state,
              listeImageCategorie: []
          })
        }
      )
}

export function obtenir_categorie_image_par_slug(state, setState, categorie_slug)
{
  const token = obtenirValeur(CONSTANTS.nomValeurToken);
    fetch(CONSTANTS.nomDomaineApi + "/categories/images/" + categorie_slug, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization':'Bearer ' + token
      },
      method: "GET",
      redirect: 'follow'
    })
      .then(res => res.json())
      .then(
        (res) => {
          if(res.contenu)
          {
            setState({
                ...state,
                categorieImageCharge:true,
                categorie: res.contenu
            })
            return;
          }
          setState({
            ...state,
            categorieImageCharge:true,
            categorieImageErreur: true,
            alertFormModificationImage: {
              type: "danger",
              texte: "Cette catégorie n'existe pas."
            }
        })
        },
        (err) => {
          console.log(err)
          setState({
              ...state,
              categorieImageCharge:true,
              categorieImageErreur: true,
              alertFormModificationImage: {
                type: "danger",
                texte: "Une erreur est survenue lors du chargement de la catégorie."
              }
          })
        }
      )
}

export function ajouter_categorie_image(state, setState)
{
  if(validator.isEmpty(state.categorie_image.nom))
  {
    setState({
      ...state,
      alertFormAjoutCategorie: {
        "type":"warning",
        "texte":"Veuillez remplir le champ 'nom' de la catégorie d'événement !"
      }
    })
    return;
  }
  if(validator.isEmpty(state.categorie_image.description))
  {
    setState({
      ...state,
      alertFormAjoutCategorie: {
        "type":"warning",
        "texte":"Veuillez remplir le champ 'description' de la catégorie d'événement !"
      }
    })
    return;
  }

  const token = obtenirValeur(CONSTANTS.nomValeurToken);
  fetch(CONSTANTS.nomDomaineApi + "/ajouter/categorie/image", {
    headers: {
      'Content-Type': 'application/json',
      'Authorization':'Bearer ' + token
    },
    body: JSON.stringify(state.categorie_image),
    method: "POST",
    redirect: 'follow'
  })
    .then(res => res.json())
    .then(
      (res) => {
        if(res.contenu)
        {
          setState({
            categorie_image: {
              "nom":"",
              "description":""
            },
            alertFormAjoutCategorie: {
              "type":"success",
              "texte":"La catégorie d'image a bel et bien été ajouté !"
            }
          })
          return;
        }
        setState({
          ...state,
          alertFormAjoutCategorie: {
            "type":"warning",
            "texte":"La catégorie d'image n'a pas été ajouté, car celle-ci existe déjà !"
          }
        })
      },
      (err) => {
        console.log(err)
        setState({
          ...state,
          alertFormAjoutCategorie: {
            "type":"danger",
            "texte":"La catégorie d'image n'a pas été ajouté !"
          }
        })
      }
    )
}

export function modifier_categorie_image(event, navigate, state, setState)
{
  event.preventDefault();

  if(validator.isEmpty(state.categorie.nom))
  {
    setState({
      ...state,
      alertFormAjoutCategorie: {
        "type":"warning",
        "texte":"Veuillez remplir le champ 'nom' de la catégorie d'événement !"
      }
    })
    return;
  }
  if(validator.isEmpty(state.categorie.description))
  {
    setState({
      ...state,
      alertFormAjoutCategorie: {
        "type":"warning",
        "texte":"Veuillez remplir le champ 'description' de la catégorie d'événement !"
      }
    })
    return;
  }

  const token = obtenirValeur(CONSTANTS.nomValeurToken);
  fetch(CONSTANTS.nomDomaineApi + "/modifier/categorie/images/" + state.categorie.id, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization':'Bearer ' + token
    },
    body: JSON.stringify({
      nom: state.categorie.nom,
      description: state.categorie.description
    }),
    method: "PUT",
    redirect: 'follow'
  })
    .then(res => res.json())
    .then(
      (res) => {
        if(res.contenu)
        {
          setState({
            categorie: {
              "nom":"",
              "description":""
            },
            alertFormModificationEvenement: {
              "type":"success",
              "texte":"La catégorie d'événement a bel et bien été modifié !"
            }
          })
          navigate("/voir-toutes-les-categories-images")
          return;
        }
        setState({
          ...state,
          alertFormModificationEvenement: {
            "type":"warning",
            "texte":"La catégorie d'événement n'a pas été modifié, car celle-ci existe déjà !"
          }
        })
      },
      (err) => {
        console.log(err)
        setState({
          ...state,
          alertFormModificationEvenement: {
            "type":"danger",
            "texte":"La catégorie d'événement n'a pas été modifié !"
          }
        })
      }
    )
}

export function supprimer_categorie_image(event, state, setState, categorie_id)
{
  event.preventDefault();
  const token = obtenirValeur(CONSTANTS.nomValeurToken);
  fetch(CONSTANTS.nomDomaineApi + "/supprimer/categorie/images/" + categorie_id, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization':'Bearer ' + token
    },
    method: "DELETE",
    redirect: 'follow'
  })
    .then(res => res.json())
    .then(
      (res) => {
        if(res.contenu)
          {
            if(state.listeImageCategorie.length === 1) 
            {
              setState({
                ...state,
                listeImageCategorie: []
              })
            }
            else
            {
              let nouvelle_liste_categorie = state.listeImageCategorie.filter((categorie) => {
                return categorie.id !== categorie_id
              })
              setState({
                listeImageCategorie: nouvelle_liste_categorie
              })
            }
          }
      },
      (err) => {
        console.log(err)
      }
    )
}