import React, { useEffect, useState } from 'react'
import { Card, Col, Row } from "react-bootstrap";
import { obtenir_statistique_total_utilisateur } from '../../services/statistiqueService';
import styles from './tableau-statistiques.module.css';

export default function TableauStatistiques() {

    const [state, setState] = useState({
        nombre_total_utilisateur: null,
        nouveau_utilisateur_derniere_semaine: null,
        temps_moyen_sur_application: null
    })

    useEffect(() => {
        state.nombre_total_utilisateur === null && obtenir_statistique_total_utilisateur("nombre_total_utilisateur", state, setState)
    }, [state])

    useEffect(() => {
        state.nombre_total_utilisateur !== null &&
        state.nouveau_utilisateur_derniere_semaine === null && obtenir_statistique_total_utilisateur("nouveau_utilisateur_derniere_semaine", state, setState)
    }, [state])

    useEffect(() => {
        state.nombre_total_utilisateur !== null &&
        state.nouveau_utilisateur_derniere_semaine !== null &&
        state.temps_moyen_sur_application === null && obtenir_statistique_total_utilisateur("temps_moyen_sur_application", state, setState)
    }, [state])

    return (
        <Row className={styles.conteneur_statistiques}>
            <Col xs={1}></Col>
            <Col xs={10}>
                <Row>
                    <Col className={styles.carte_statistique} lg={4} xs={12}>
                        <Card className={styles.fond_carte}>
                            <Card.Body>
                                <Card.Title>Nombre d'utilisateurs</Card.Title>
                                <Card.Text>{state.nombre_total_utilisateur + " utilisateur(s)"}</Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col className={styles.carte_statistique} lg={4} xs={12}>
                        <Card className={styles.fond_carte}>
                            <Card.Body>
                                <Card.Title>Nouveaux utilisateurs cette semaine</Card.Title>
                                <Card.Text>{state.nouveau_utilisateur_derniere_semaine + " utilisateur(s)"}</Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col className={styles.carte_statistique} lg={4} xs={12}>
                        <Card className={styles.fond_carte}>
                            <Card.Body>
                                <Card.Title>Temps moyen sur l'application</Card.Title>
                                <Card.Text>{state.temps_moyen_sur_application + " s"}</Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Col>
            <Col xs={1}></Col>
        </Row>
    )
}