import React, { useEffect, useState } from 'react'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import styles from './form-modifier-un-utilisateur.module.css';
import HFAlerte from '../commun/hf-alerte';
import { modifier_un_utilisateur, obtenir_utilisateur_par_id } from '../../services/utilisateurService';
import { useParams } from 'react-router-dom';
import IconeChargement from '../commun/icone-chargement';

export default function FormulaireModifierUnUtilisateur() {

    const [state, setState] = useState({
        erreurModificationUtilisateur: {
            type: "",
            texte:""
        },
        utilisateur: null
    })

    let params = useParams();

    useEffect(() => {
        !state.utilisateur && obtenir_utilisateur_par_id(params.utilisateur_id, state, setState); 
    }, [state, params.utilisateur_id])

    return (
        <>
            {
                state.erreurModificationUtilisateur.texte && <HFAlerte type={state.erreurModificationUtilisateur.type} texte={state.erreurModificationUtilisateur.texte}/>
            }
            {
                !state.utilisateur &&
                <IconeChargement/>
            }
            {
                state.utilisateur &&
                <Row className={styles.conteneur_form_connexion}>
                    <Col lg="4" xs="1"></Col>
                    <Col lg="4" xs="10" className="text-center">
                        <Form 
                            onSubmit={(event) => {
                                event.preventDefault();
                                modifier_un_utilisateur(params.utilisateur_id, state, setState)
                            }} 
                            noValidate
                        >
                            <Form.Group className="mb-3" controlId="hf_courriel">
                                <Form.Label>Prénom de l'utilisateur*</Form.Label>
                                <Form.Control 
                                    onChange={(event) => { 
                                        setState({
                                            ...state,
                                            utilisateur: {
                                                ...state.utilisateur,
                                                prenom: event.target.value
                                            }
                                        })
                                    }} 
                                    value={state.utilisateur.prenom ?? ""}
                                    type="text" 
                                    placeholder="John" 
                                    autoFocus={true}
                                />
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="hf_courriel">
                                <Form.Label>Nom de l'utilisateur*</Form.Label>
                                <Form.Control 
                                    onChange={(event) => { 
                                        setState({
                                            ...state,
                                            utilisateur: {
                                                ...state.utilisateur,
                                                nom: event.target.value
                                            }
                                        })
                                    }}
                                    value={state.utilisateur.nom ?? ""}
                                    type="text" 
                                    placeholder="Doe"
                                />
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="hf_courriel">
                                <Form.Label>Courriel de l'utilisateur*</Form.Label>
                                <Form.Control 
                                    onChange={(event) => { 
                                        setState({
                                            ...state,
                                            utilisateur: {
                                                ...state.utilisateur,
                                                courriel: event.target.value
                                            }
                                        })
                                    }}
                                    value={state.utilisateur.courriel}
                                    type="email" 
                                    placeholder="exemple@havefunquebec.com"
                                />
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="hf_courriel">
                                <Form.Label>Date de naissance*</Form.Label>
                                <Form.Control 
                                    onChange={(event) => { 
                                        setState({
                                            ...state,
                                            utilisateur: {
                                                ...state.utilisateur,
                                                date_naissance: event.target.value
                                            }
                                        })
                                    }} 
                                    value={state.utilisateur.date_naissance ?? ""}
                                    type="date" 
                                    placeholder="1996-06-10"
                                />
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="hf_courriel">
                                <Form.Label>Code postal*</Form.Label>
                                <Form.Control 
                                    onChange={(event) => { 
                                        setState({
                                            ...state,
                                            utilisateur: {
                                                ...state.utilisateur,
                                                code_postal: event.target.value
                                            }
                                        })
                                    }} 
                                    value={state.utilisateur.code_postal ?? ""}
                                    type="text" 
                                    placeholder="H1W 1V8"
                                />
                            </Form.Group>
                            {
                                state.utilisateur.role_id !== "1" &&
                                <Form.Group className="mb-3" controlId="hf_courriel">
                                    <Form.Label>Type de l'utilisateur*</Form.Label>
                                    <Form.Select
                                        onChange={(event) => { 
                                            setState({
                                                ...state,
                                                utilisateur: {
                                                    ...state.utilisateur,
                                                    role_id: String(event.target.value)
                                                }
                                            })
                                        }}
                                        value={state.utilisateur.role_id ?? "3"}
                                    >
                                        <option value="">Sélectionner un rôle</option>
                                        <option value="2">Administrateur</option>
                                        <option value="3">Consommateur</option>
                                        <option value="4">Testeur</option>
                                        <option value="5">Annonceur</option>
                                    </Form.Select>
                                </Form.Group>
                            }
                            <Form.Group className="mb-3" controlId="hf_courriel">
                                <Form.Label>Réglages de l'utilisateur*</Form.Label>
                                <Form.Check
                                    onClick={() => {
                                        setState({
                                            ...state,
                                            utilisateur: {
                                                ...state.utilisateur,
                                                est_actif: state.utilisateur.est_actif === 1 ? 0 : 1
                                            }
                                        })
                                    }}
                                    value={state.utilisateur.est_actif}
                                    type="checkbox"
                                    defaultChecked={state.utilisateur.est_actif ?? 0}
                                    label={"Est actif"}
                                />
                                <Form.Check
                                    onClick={() => {
                                        setState({
                                            ...state,
                                            utilisateur: {
                                                ...state.utilisateur,
                                                est_inscrit_infolettre: state.utilisateur.est_inscrit_infolettre === 1 ? 0 : 1
                                            }
                                        })
                                    }}
                                    value={state.utilisateur.est_inscrit_infolettre ?? 0}
                                    type="checkbox"
                                    defaultChecked={state.utilisateur.est_inscrit_infolettre}
                                    label={"Est inscrit à l'infolettre"}
                                />
                            </Form.Group>
                            <Button variant="primary" type="submit">
                                Modifier l'utilisateur
                            </Button>
                        </Form>
                    </Col>
                    <Col lg="4" xs="1"></Col>
                </Row>
            }
        </>
    );
  }