import React, { useEffect, useState } from 'react'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Alert, Badge, Button, Card, Form, FormGroup, Table } from 'react-bootstrap';
import styles from './liste-utilisateur.module.css';
import { creerCleUnique } from '../../utilitaires/listUtil';
import { obtenir_tous_les_utilisateurs, reinitialiser_image_profil_with_list, supprimer_utilisateur } from '../../services/utilisateurService';
import { useNavigate } from 'react-router-dom';
import { CONSTANTS } from '../../config/constants';

function genererlisteUtilisateur(state, setState, width) {

    if(width <= 925)
    {
      return (
        state.listeUtilisateur &&
        state.listeUtilisateur
        .filter((utilisateur) => {
          if(state.texteRecherche)
          {
            if(utilisateur.prenom && utilisateur.prenom.toLowerCase().includes(state.texteRecherche.toLowerCase())) return true;
            if(utilisateur.nom && utilisateur.nom.toLowerCase().includes(state.texteRecherche.toLowerCase())) return true;
            if(utilisateur.nom && utilisateur.prenom && (utilisateur.prenom + " " + utilisateur.nom).toLowerCase().includes(state.texteRecherche.toLowerCase())) return true;
            if(utilisateur.courriel && utilisateur.courriel.toLowerCase().includes(state.texteRecherche.toLowerCase())) return true;
            return false;
          }
          return true;
        })
        .slice(state.indexUtilisateur, state.indexUtilisateur + 10)
        .map((utilisateur) => {
          return (
            <Card className={styles.conteneur_liste_utilisateur}>
              <Card.Body>
                <Card.Title className={styles.titre_carte_utilisateur}>{utilisateur.prenom +  " " + utilisateur.nom}</Card.Title>
                <img 
                    className={'img-fluid ' + styles.image_profil}
                    src={
                      utilisateur.image_profil ? 
                      CONSTANTS.nomDomaineApi + '/images/profil/' + utilisateur.image_profil :
                      CONSTANTS.nomDomaineApi + '/images/profil/defaut/image-profil-defaut.png'
                    }
                    alt="Représente l'utilisateur."
                  />
                <a href={"/voir-utilisateur/" + utilisateur.id}>
                  <Badge className={styles.hf_bouton_liste} bg="primary">Voir l'utilisateur</Badge>
                </a>
                <a href={"/modifier-utilisateur/" + utilisateur.id}>
                  <Badge className={styles.hf_bouton_liste} bg="primary">Modifier l'utilisateur</Badge>
                </a>
                {
                    utilisateur.image_profil &&
                    <Col sm="12">
                        <Button 
                            onClick={() => {
                                reinitialiser_image_profil_with_list(utilisateur.id, state, setState)
                            }}
                            className={styles.hf_bouton_liste}
                        >Réinitialiser image de profil</Button>
                    </Col>
                }
                {
                  utilisateur.role_id !== 1 &&
                  <Badge 
                    className={styles.hf_bouton_liste} 
                    onClick={(event) => { 
                      event.preventDefault();
                      supprimer_utilisateur(utilisateur.id, state, setState)
                    }} 
                    bg="primary"
                  >Supprimer l'utilisateur</Badge>
                }
              </Card.Body>
            </Card>
          )
        })
      )
    }
  
    return (
      <Table className={styles.conteneur_liste_utilisateur} variant="dark" striped bordered hover size="sm" responsive="sm">
        <thead>
          <tr>
            <th>Image profil</th>
            <th>Nom complet</th>
            <th>Courriel</th>
            <th>Rôle</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {
            state.listeUtilisateur &&
            state.listeUtilisateur
            .filter((utilisateur) => {
              if(state.texteRecherche)
              {
                if(utilisateur.prenom && utilisateur.prenom.toLowerCase().includes(state.texteRecherche.toLowerCase())) return true;
                if(utilisateur.nom && utilisateur.nom.toLowerCase().includes(state.texteRecherche.toLowerCase())) return true;
                if(utilisateur.nom && utilisateur.prenom && (utilisateur.prenom + " " + utilisateur.nom).toLowerCase().includes(state.texteRecherche.toLowerCase())) return true;
                if(utilisateur.courriel && utilisateur.courriel.toLowerCase().includes(state.texteRecherche.toLowerCase())) return true;
                return false;
              }
              return true;
            })
            .slice(state.indexUtilisateur, state.indexUtilisateur + 10)
            .map((utilisateur) => {
              return (
                <tr key={creerCleUnique()}>
                  <td>
                    <img 
                      className={'img-fluid ' + styles.image_profil}
                      src={
                        utilisateur.image_profil ? 
                        CONSTANTS.nomDomaineApi + '/images/profil/' + utilisateur.image_profil :
                        CONSTANTS.nomDomaineApi + '/images/profil/defaut/image-profil-defaut.png'
                      }
                      alt="Représente l'utilisateur."
                    />
                  </td>
                  <td>{utilisateur.prenom + " " + utilisateur.nom}</td>
                  <td>{utilisateur.courriel}</td>
                  <td>{utilisateur.nom_role}</td>
                  <td>
                    <a href={"/voir-utilisateur/" + utilisateur.id}>
                      <Badge className={styles.hf_bouton_liste} bg="primary">Voir l'utilisateur</Badge>
                    </a>
                    <a href={"/modifier-utilisateur/" + utilisateur.id}>
                      <Badge className={styles.hf_bouton_liste} bg="primary">Modifier l'utilisateur</Badge>
                    </a>
                    {
                        utilisateur.image_profil &&
                        <Badge 
                            onClick={() => {
                                reinitialiser_image_profil_with_list(utilisateur.id, state, setState)
                            }}
                            className={styles.hf_bouton_liste}
                        >Réinitialiser image de profil</Badge>
                    }
                    {
                      utilisateur.role_id !== 1 &&
                      <Badge 
                        className={styles.hf_bouton_liste} 
                        onClick={(event) => { 
                          event.preventDefault();
                          supprimer_utilisateur(utilisateur.id, state, setState)
                        }} 
                        bg="primary"
                      >Supprimer l'utilisateur</Badge>
                    }
                  </td>
                </tr>
              )
            })
          }
        </tbody>
      </Table>
    );
  }

export default function ListeUtilisateur() {
    const [state, setState] = useState({
      listeUtilisateur: null,
      texteRecherche: "",
      indexUtilisateur: 0
    });
    const width = window.innerWidth;
    const navigate = useNavigate();

    useEffect(() => {
        !state.listeUtilisateur && obtenir_tous_les_utilisateurs(state, setState); 
    }, [state])
    
    return (
      <>
        <Row className='text-center'>
          <Col lg="4"></Col>
          <Col lg="4">
            <Button
              onClick={() => {
                navigate("/ajouter-utilisateur")
              }}
              className={styles.bouton_ajout_utilisateur}
            >Ajouter utilisateur</Button>
          </Col>
          <Col lg="4"></Col>
        </Row>
        <Row className={'text-center ' + styles.conteneur_bouton_pagination}>
              <Col lg="4" xs="1"></Col>
              <Col lg="4" xs="10">
                <Button
                  className={styles.bouton_precedent_utilisateur}
                  disabled={state.listeUtilisateur && state.indexUtilisateur <= 0}
                  onClick={() => {
                    if(state.listeUtilisateur && state.indexUtilisateur > 0){
                      setState({
                        ...state,
                        indexUtilisateur: state.indexUtilisateur - 10
                      })
                    }
                  }}
                >Précédent</Button>
              </Col>
              <Col lg="4" xs="1"></Col>
              <Col lg="4" xs="1"></Col>
              <Col lg="4" xs="10" className="text-center">
                  <Form
                    onSubmit={(event) => {
                      event.preventDefault();
                    }}
                  >
                    <FormGroup>
                      <input 
                        className='form-control'
                        type='text'
                        placeholder='Rechercher un utilisateur'
                        value={state.texteRecherche}
                        onChange={(event) => {
                          setState({
                            ...state,
                            texteRecherche: event.target.value
                          })
                        }}
                      />
                    </FormGroup>
                  </Form>
              </Col>
              <Col lg="4" xs="1"></Col>
              <Col lg="4" xs="1"></Col>
              <Col lg="4" xs="10">
                <Button
                  className={styles.bouton_suivant_utilisateur}
                  disabled={state.listeUtilisateur && state.indexUtilisateur > state.listeUtilisateur.length - 10}
                  onClick={() => {
                    if(state.listeUtilisateur && state.indexUtilisateur < state.listeUtilisateur.length){
                      setState({
                        ...state,
                        indexUtilisateur: state.indexUtilisateur + 10
                      })
                    }
                  }}
                >Suivant</Button>
              </Col>
              <Col lg="4" xs="1"></Col>
          </Row>
          <Row>
              <Col xs="1"></Col>
              <Col xs="10" className="text-center">
                  {
                      state.listeUtilisateur && genererlisteUtilisateur(state, setState, width)
                  }
                  {
                      state.listeUtilisateur &&
                      state.listeUtilisateur.length === 0 &&
                      <Alert variant='warning' className={styles.alerte_aucune_categorie}>Aucun utilisateur à afficher !</Alert>
                  }
              </Col>
              <Col xs="1"></Col>
          </Row>
        </>
    );
  }