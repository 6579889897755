import React, { useEffect } from 'react';
import DocumentMeta from 'react-document-meta';
import Container from 'react-bootstrap/Container';
import Navigation from '../composants/commun/navigation';
import { useState } from 'react';
import TitrePage from '../composants/commun/titre-page';
import PiedPage from '../composants/commun/pied-page';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { verifier_token } from '../services/connexionService';
import { useLocation, useNavigate } from 'react-router-dom';
import { CONSTANTS } from '../config/constants';
import IconeChargement from '../composants/commun/icone-chargement';
import FormulaireAjoutCategorieImage from '../composants/ajouter-categorie-image/formulaire-ajouter-categorie-image';

export default function PageAjouterCategorieImage() {
    const [state, setState] = useState({
      enChargement: true,
      meta: {
        title: 'Gestion Emotions | Ajouter une catégorie d\'image'
      } 
    });
    const navigate = useNavigate()
    const location = useLocation()
    
    useEffect(() => {
      verifier_token(navigate, location);
      const myInterval = setInterval(verifier_token, CONSTANTS.tempsRequisAvantVerificationCredentiel, navigate, location);
      if(state.enChargement)
      {
        setTimeout(setState, 1000, {
          ...state,
          enChargement: false,
        })
      }
      return () => {
        clearInterval(myInterval)
      }
    }, [navigate, location, state]);

    return (
      <DocumentMeta {...state.meta}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <Container fluid>
            <Navigation></Navigation>
            {
              state.enChargement &&
              <>
                <TitrePage titre_page="Vérification de votre identité..."></TitrePage>
                <IconeChargement/>
              </>
            }
            {
              !state.enChargement &&
              <>
                <TitrePage titre_page="Ajouter une catégorie d'image"></TitrePage>
                <FormulaireAjoutCategorieImage />
              </>
            }
            <PiedPage></PiedPage>
          </Container>
        </LocalizationProvider>
      </DocumentMeta>
    );
  }