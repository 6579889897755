import React, { useEffect } from 'react'
import DocumentMeta from 'react-document-meta';
import Container from 'react-bootstrap/Container';
import Navigation from '../composants/commun/navigation';
import { useState } from 'react';
import { useLocation, useNavigate } from "react-router-dom";
import TitrePage from '../composants/commun/titre-page';
import PiedPage from '../composants/commun/pied-page';
import FormulaireMotDePasseOublier from '../composants/mot-de-passe-oublier/formulaire-mot-de-passe-oublier';
import { verifier_token } from '../services/connexionService';

export default function PageMotDePasseOublier() {

    //Création des constantes de la page connexion
    const [state] = useState({
      meta: {
        title: 'Gestion Emotions | Réinitialiser son mot de passe'
      }
    });
    const navigate = useNavigate()
    const location = useLocation();

    useEffect(() => {
      verifier_token(navigate, location)
    })

    //On créer le rendu de la page connexion
    return (
      <DocumentMeta {...state.meta}>
        <Container fluid>
          <Navigation></Navigation>
          <TitrePage titre_page="Réinitialisation du mot de passe"></TitrePage>
          <FormulaireMotDePasseOublier></FormulaireMotDePasseOublier>
          <PiedPage></PiedPage>
        </Container>
      </DocumentMeta>
    );
  }