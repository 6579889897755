import React, { useState } from 'react'
import {
    useJsApiLoader,
    GoogleMap,
    Marker,
    InfoWindow
  } from "@react-google-maps/api";
import { useEffect } from 'react';
import { obtenir_tous_les_evenements } from '../../services/evenementService';
import { Button, Col, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import HFAlerte from '../commun/hf-alerte';
import styles from "./carte-evenement.module.css";
import { CONSTANTS } from '../../config/constants';

export default function CarteEvenement() {
    const [state, setState] = useState({
        listeEvenement: null,
        mapRef: null,
        selectedPlace: null,
        markerMap:{},
        center:{ lat: 45.8511, lng: -72.0774 },
        zoom: 7,
        clickedLatLng:null,
        infoOpen:false, 
        icon: {
            path: "M10.453 14.016l6.563-6.609-1.406-1.406-5.156 5.203-2.063-2.109-1.406 1.406zM12 2.016q2.906 0 4.945 2.039t2.039 4.945q0 1.453-0.727 3.328t-1.758 3.516-2.039 3.070-1.711 2.273l-0.75 0.797q-0.281-0.328-0.75-0.867t-1.688-2.156-2.133-3.141-1.664-3.445-0.75-3.375q0-2.906 2.039-4.945t4.945-2.039z",
            fillColor: "blue",
            fillOpacity: 0.6,
            strokeWeight: 0,
            rotation: 0,
            scale: 1,
            anchor: {
                x: 15,
                y: 30
            },
        }
    })

    const loadHandler = map => {
        // Store a reference to the google map instance in state
        setState({
            ...state,
            mapRef: map
        });
    };

    // We have to create a mapping of our places to actual Marker objects
    const markerLoadHandler = (marker, place) => {
        return setState(prevState => {
            return {
                ...prevState,
                markerMap: {
                    ...prevState.markerMap,
                    [place.id]: marker
                }
            }
        });
    };

    const markerClickHandler = (event, place) => {
        setState({
            ...state,
            selectedPlace: place,
            infoOpen: true,
            zoom: 25,
            center: {
                lat: Number(place.latitude),
                lng: Number(place.longitude)
            }
        });
    };

    const { isLoaded } = useJsApiLoader({
        // Enter your own Google Maps API key
        googleMapsApiKey: CONSTANTS.cleGoogleApi
    })

    useEffect(() => {
        !state.listeEvenement && obtenir_tous_les_evenements(state, setState)
    }, [state])

    return (
        <Row>
            <Col xs={1}></Col>
            <Col xs={10} className="text-center">
                {
                    state.listeEvenement && 
                    state.listeEvenement.length === 0 &&
                    isLoaded &&
                    <HFAlerte
                        aucunRow={true}
                        type="warning"
                        texte="Aucun événement n'a été trouvé, ainsi, la carte ne peut être affiché."
                    />
                }
                {
                    !isLoaded &&
                    <HFAlerte
                        aucunRow={true}
                        type="danger"
                        texte="La carte n'a pas pu être chargé !"
                    />
                }
                {
                    isLoaded && 
                    state.listeEvenement &&
                    state.listeEvenement.length > 0 &&
                    <GoogleMap
                        onLoad={loadHandler}
                        // Save the user's map click position
                        onClick={e =>

                            setState({
                                ...state,
                                clickedLatLng: e.latLng.toJSON()
                            })
                        }
                        center={state.center}
                        zoom={state.zoom}
                        onZoomChanged={() => {
                            if(state.mapRef)
                            {
                                setState({
                                    ...state,
                                    zoom: state.mapRef.zoom
                                })
                            }
                        }}
                        options={{
                            mapTypeId: 'satellite'
                        }}
                        mapContainerStyle={{
                            height: "70vh",
                            width: "100%"
                        }}
                    >
                        {
                            state.listeEvenement && 
                            state.listeEvenement.map(evenement => (
                                <Marker
                                    key={evenement.id}
                                    position={{
                                        lat: Number(evenement.latitude),
                                        lng: Number(evenement.longitude)
                                    }}
                                    onLoad={marker => markerLoadHandler(marker, evenement)}
                                    onClick={event => markerClickHandler(event, evenement)}
                                />
                            ))
                        }
                        {state.infoOpen && state.selectedPlace && (
                            <InfoWindow
                                position={{
                                    lat: Number(state.selectedPlace.latitude),
                                    lng: Number(state.selectedPlace.longitude)
                                }}
                                onCloseClick={() => setState({...state, infoOpen: false})}
                                fillColor="black"
                            >
                                <div className={styles.info_window_google}>
                                    <h3 className={styles.info_window_titre}>{state.selectedPlace.nom}</h3>
                                    <p className={styles.info_window_texte}>{state.selectedPlace.description}</p>
                                    <Link to={"/voir-evenement/" + state.selectedPlace.id}><Button>Voir l'événement</Button></Link>
                                </div>
                            </InfoWindow>
                        )}
                    </GoogleMap>
                }
            </Col>
            <Col xs={1}></Col>
        </Row>
    );
  }