import React, { useState } from 'react'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { Link, useNavigate } from "react-router-dom";
import { connexion } from '../../services/connexionService';
import styles from './formulaire-connexion.module.css';
import HFAlerte from '../commun/hf-alerte';

export default function FormulaireConnexion() {

    const [state, setState] = useState({
        erreurConnexion: {
            type: "",
            texte:""
        },
        identifiantsConnexion: {
            courriel: "",
            mot_de_passe: ""
        }
    })
    const navigate = useNavigate()

    return (
        <>
            {
                state.erreurConnexion.texte && <HFAlerte type={state.erreurConnexion.type} texte={state.erreurConnexion.texte}/>
            }
            <Row className={styles.conteneur_form_connexion}>
                <Col lg="4" xs="1"></Col>
                <Col lg="4" xs="10" className="text-center">
                    <Form 
                        onSubmit={(event) => {
                            event.preventDefault();
                            connexion(navigate, state, setState)
                        }} 
                        noValidate
                    >
                        <Form.Group className="mb-3" controlId="hf_courriel">
                            <Form.Label>Votre courriel*</Form.Label>
                            <Form.Control 
                                onChange={(event) => { 
                                    setState({
                                        ...state,
                                        erreurConnexion: {
                                            type: "",
                                            texte:""
                                        },
                                        identifiantsConnexion: {
                                            ...state.identifiantsConnexion,
                                            courriel: event.target.value
                                        }
                                    })
                                }} 
                                type="email" 
                                placeholder="exemple@havefunquebec.com" 
                                autoFocus={true}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="hf_mot_de_passe">
                            <Form.Label>Votre mot de passe*</Form.Label>
                            <Form.Control 
                                onChange={(event) => {  
                                    setState({
                                        ...state,
                                        erreurConnexion: {
                                            type: "",
                                            texte:""
                                        },
                                        identifiantsConnexion: {
                                            ...state.identifiantsConnexion,
                                            mot_de_passe: event.target.value
                                        }
                                    })
                                }} 
                                type="password" 
                                placeholder="exemple@#!motPAsse" 
                            />
                        </Form.Group>
                        <Form.Group className={styles.conteneur_bouton_mot_de_passe_oublier}>
                            <Link className={styles.bouton_mot_de_passe_oublier} to="/mot-de-passe-oublier">J'ai oublié mon mot de passe</Link>
                        </Form.Group>
                        <Button className={styles.bouton_connexion} variant="primary" type="submit">
                            Me connecter
                        </Button>
                    </Form>
                </Col>
                <Col lg="4" xs="1"></Col>
            </Row>
        </>
    );
  }