import axios from "axios";
import { CONSTANTS } from "../config/constants";
import { obtenirValeur } from "../utilitaires/localStorageUtil";

export function obtenir_tous_les_utilisateurs(state, setState)
{
    const token = obtenirValeur(CONSTANTS.nomValeurToken);
    fetch(CONSTANTS.nomDomaineApi + "/obtenir/utilisateur", {
      headers: {
        'Content-Type': 'application/json',
        'Authorization':'Bearer ' + token
      },
      method: "GET"
    })
      .then(
        async (response) => {
            let responseJSON = await response.json();
            if(response.status === 200)
            {
                setState({
                    ...state,
                    listeUtilisateur: responseJSON.contenu.utilisateurs
                })
            }
            else
            {
                setState({
                    ...state,
                    listeUtilisateur: []
                })
            }
        }, 
        (error) => {
            console.log(error)
            setState({
                ...state,
                listeUtilisateur: []
            })
        }
      )
}

export function obtenir_utilisateur_par_id(utilisateur_id, state, setState)
{
    const token = obtenirValeur(CONSTANTS.nomValeurToken);
    fetch(CONSTANTS.nomDomaineApi + "/obtenir/utilisateur/" + String(utilisateur_id), {
        headers: {
            'Content-Type': 'application/json',
            'Authorization':'Bearer ' + token
        },
        method: "GET"
    })
        .then(
            async (response) => {

                let responseJSON = await response.json();

                if(response.status === 200)
                {
                    setState({
                        ...state,
                        utilisateur: responseJSON.contenu.utilisateur
                    })
                    window.scrollTo(0, 0)
                }
                else
                {
                    setState({
                        ...state,
                        erreurAjoutUtilisateur: {
                            type: "success",
                            texte: responseJSON.message
                        }
                    })
                    window.scrollTo(0, 0)
                }
            },
            (error) => {
                console.log(error)
                setState({
                    ...state,
                    erreurAjoutUtilisateur: {
                        type: "success",
                        texte: "Une erreur inattendue est survenue."
                    }
                })
                window.scrollTo(0, 0)
            }
        )
}

export function ajouter_un_utilisateur(state, setState)
{
    const token = obtenirValeur(CONSTANTS.nomValeurToken);
    fetch(CONSTANTS.nomDomaineApi + "/ajouter-utilisateur", {
        headers: {
            'Content-Type': 'application/json',
            'Authorization':'Bearer ' + token
        },
        body: JSON.stringify(state.nouvelUtilisateur),
        method: "POST"
    })
        .then(
            async (response) => {

                let responseJSON = await response.json();

                if(response.status === 200)
                {
                    setState({
                        ...state,
                        erreurAjoutUtilisateur: {
                            type: "success",
                            texte: responseJSON.message
                        }
                    })
                    window.scrollTo(0, 0)
                }
                else
                {
                    setState({
                        ...state,
                        erreurAjoutUtilisateur: {
                            type: "success",
                            texte: responseJSON.message
                        }
                    })
                    window.scrollTo(0, 0)
                }
            },
            (error) => {
                console.log(error)
                setState({
                    ...state,
                    erreurAjoutUtilisateur: {
                        type: "success",
                        texte: "Une erreur inattendue est survenue."
                    }
                })
                window.scrollTo(0, 0)
            }
        )
}

export function modifier_un_utilisateur(utilisateur_id, state, setState)
{
    const token = obtenirValeur(CONSTANTS.nomValeurToken);
    fetch(CONSTANTS.nomDomaineApi + "/modifier-utilisateur/" + String(utilisateur_id), {
        headers: {
            'Content-Type': 'application/json',
            'Authorization':'Bearer ' + token
        },
        body: JSON.stringify(state.utilisateur),
        method: "POST"
    })
        .then(
            async (response) => {

                let responseJSON = await response.json();

                if(response.status === 200)
                {
                    setState({
                        ...state,
                        erreurModificationUtilisateur: {
                            "type": "success",
                            "texte": responseJSON.message
                        }
                    })
                    window.scrollTo(0, 0)
                }
                else
                {
                    setState({
                        ...state,
                        erreurModificationUtilisateur: {
                            "type": "danger",
                            "texte": responseJSON.message
                        }
                    })
                    window.scrollTo(0, 0)
                }
            },
            (error) => {
                console.log(error)
                setState({
                    ...state,
                    erreurModificationUtilisateur: {
                        "type": "danger",
                        "texte": "Une erreur inattendue est survenue."
                    }
                })
                window.scrollTo(0, 0)
            }
        )
}

export function reinitialiser_image_profil(utilisateur_id, state, setState)
{
    const token = obtenirValeur("token")
    axios.post(
        CONSTANTS.nomDomaineApi + "/reinitialiser-image-profil",
        {
            "utilisateur_id": utilisateur_id
        },
        {
            headers: {
                "Authorization": "Bearer " + token
            }
        }
    )
        .then((response) => {
            if(response.status === 200)
            {
                setState({
                    ...state,
                    utilisateur: null
                })
            }
        })
        .catch((error) => {
            console.log(error)
        })
}

export function reinitialiser_image_profil_with_list(utilisateur_id, state, setState)
{
    const token = obtenirValeur("token")
    axios.post(
        CONSTANTS.nomDomaineApi + "/reinitialiser-image-profil",
        {
            "utilisateur_id": utilisateur_id
        },
        {
            headers: {
                "Authorization": "Bearer " + token
            }
        }
    )
        .then((response) => {
            if(response.status === 200)
            {
                setState({
                    ...state,
                    listeUtilisateur: null
                })
            }
        })
        .catch((error) => {
            console.log(error)
        })
}

export function supprimer_utilisateur(utilisateur_id, state, setState)
{
    const token = obtenirValeur(CONSTANTS.nomValeurToken);
    fetch(CONSTANTS.nomDomaineApi + "/supprimer-utilisateur", {
        headers: {
            'Content-Type': 'application/json',
            'Authorization':'Bearer ' + token
        },
        body: JSON.stringify({
            "utilisateur_id": String(utilisateur_id)
        }),
        method: "DELETE"
    })
        .then(
            async (response) => {
                if(response.status === 200)
                {
                    if(state.listeUtilisateur.length === 1) 
                    {
                        setState({
                            ...state,
                            listeUtilisateur: []
                        })
                    }
                    else
                    {
                        setState({
                            ...state,
                            listeUtilisateur: state.listeUtilisateur.filter((utilisateur) => {
                                return utilisateur.id !== utilisateur_id
                            })
                        })
                    }
                }
            },
            (error) => {
                console.log(error)
            }
        )
}