import React from 'react'
import DocumentMeta from 'react-document-meta';
import Container from 'react-bootstrap/Container';
import Navigation from '../composants/commun/navigation';
import { useState } from 'react';
import TitrePage from '../composants/commun/titre-page';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Link } from 'react-router-dom';
import PiedPage from '../composants/commun/pied-page';
import Image404 from '../composants/commun/image-404';

export default function PageNonTrouve() {
    const [state] = useState({
      meta: {
        title: 'Gestion Emotions | Page non trouvé'
      } 
    });
    return (
      <DocumentMeta {...state.meta}>
        <Container fluid>
          <Navigation></Navigation>
          <TitrePage titre_page="Page non trouvé"></TitrePage>
          <Image404></Image404>
          <Row>
            <Col xs="4"></Col>
            <Col xs="4" className='text-center'>
              <br/>
              <Link className='btn btn-primary' to="/">Retourner à la page d'accueil</Link>
            </Col>
            <Col xs="4"></Col>
          </Row>
          <PiedPage></PiedPage>
        </Container>
      </DocumentMeta>
    );
  }