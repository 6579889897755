import React from 'react'
import Container from 'react-bootstrap/Container';
import { obtenirValeur, supprimerValeur } from '../utilitaires/localStorageUtil';
import { Navigate } from 'react-router-dom';
import { CONSTANTS } from '../config/constants';

export default function PageDeconnexion() {
  supprimerValeur(CONSTANTS.nomValeurToken);
  const token = obtenirValeur(CONSTANTS.nomValeurToken)
  return (
    <Container fluid>
      {!token && (
        <Navigate to="/connexion" replace={true} />
      )}
    </Container>
  );
}