import React, { useEffect } from 'react'
import DocumentMeta from 'react-document-meta';
import { useState } from 'react';
import TitrePage from '../composants/commun/titre-page';
import { verifier_token } from '../services/connexionService';
import Navigation from '../composants/commun/navigation';
import { useLocation, useNavigate } from "react-router-dom";
import CarteEvenement from '../composants/tableau-de-bord/carte-evenement';
import TableauStatistiques from '../composants/tableau-de-bord/tableau-statistiques';
import PiedPage from '../composants/commun/pied-page';
import IconeChargement from '../composants/commun/icone-chargement';
import { CONSTANTS } from '../config/constants';

export default function PageTableauDeBord() {
  const [state, setState] = useState({
    enChargement: true,
    meta: {
      title: 'Gestion Emotions | Tableau de bord'
    } 
  });
  const navigate = useNavigate()
  const location = useLocation()
  
  useEffect(() => {
    verifier_token(navigate, location);
    const myInterval = setInterval(verifier_token, CONSTANTS.tempsRequisAvantVerificationCredentiel, navigate, location);
    if(state.enChargement)
      {
        setTimeout(setState, 1000, {
          ...state,
          enChargement: false,
        })
      }
    return () => {
      clearInterval(myInterval)
    }
  }, [navigate, location, state]);

  return (
    <DocumentMeta {...state.meta}>
      <Navigation></Navigation>
      {
        state.enChargement &&
        <>
          <TitrePage titre_page="Vérification de votre identité..."></TitrePage>
          <IconeChargement/>
        </>
      }
      {
        !state.enChargement &&
        <>
          <TitrePage titre_page="Tableau de bord"></TitrePage>
          <TableauStatistiques></TableauStatistiques>
          <CarteEvenement></CarteEvenement>
        </>
      }
      <PiedPage></PiedPage>
    </DocumentMeta>
  );
}