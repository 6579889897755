import React, { useEffect, useState } from 'react'
import DocumentMeta from 'react-document-meta';
import Container from 'react-bootstrap/Container';
import Navigation from '../composants/commun/navigation';
import PiedPage from '../composants/commun/pied-page';
import { useLocation, useNavigate } from 'react-router-dom';
import { verifier_token } from '../services/connexionService';
import TitrePage from '../composants/commun/titre-page';
import { CONSTANTS } from '../config/constants';
import IconeChargement from '../composants/commun/icone-chargement';
import FicheUtilisateur from '../composants/voir-utilisateur/fiche-utilisateur';

export default function PageVoirUtilisateur() {
    const [state, setState] = useState({
      enChargement: true,
      meta: {
        title: 'Gestion Emotions | Voir un événement'
      }
    });

    const navigate = useNavigate();
    const location = useLocation();
    
    useEffect(() => {
      verifier_token(navigate, location);
      const myInterval = setInterval(verifier_token, CONSTANTS.tempsRequisAvantVerificationCredentiel, navigate, location);
      if(state.enChargement)
      {
        setTimeout(setState, 1000, {
          ...state,
          enChargement: false,
        })
      }
      return () => {
        clearInterval(myInterval)
      }
    }, [navigate, location, state]);

    return (
      <DocumentMeta {...state.meta}>
        <Container fluid>
          <Navigation></Navigation>
          {
            state.enChargement &&
            <>
              <TitrePage titre_page="Vérification de votre identité..."></TitrePage>
              <IconeChargement/>
            </>
          }
          {
            !state.enChargement &&
            <>
              <FicheUtilisateur></FicheUtilisateur>
            </>
          }
          <PiedPage></PiedPage>
        </Container>
      </DocumentMeta>
    );
  }