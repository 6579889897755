import React from 'react'
import { Col, Row } from "react-bootstrap";
import Image from 'react-bootstrap/Image';
import styles from './image-404.module.css';

export default function Image404() {
    return (
        <Row>
            <Col xs="4"></Col>
            <Col xs="4">
              <Image fluid="true" className={styles.image_404} src='https://api.havefunquebec.com/images/image-erreur/erreur-404.svg'></Image>
            </Col>
            <Col xs="4"></Col>
        </Row>
    )
}