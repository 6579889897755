import React, { useEffect } from 'react'
import DocumentMeta from 'react-document-meta';
import Container from 'react-bootstrap/Container';
import Navigation from '../composants/commun/navigation';
import { useState } from 'react';
import FormulaireConnexion from '../composants/connexion/formulaire-connexion';
import { useLocation, useNavigate } from "react-router-dom";
import TitrePage from '../composants/commun/titre-page';
import PiedPage from '../composants/commun/pied-page';
import { verifier_token } from '../services/connexionService';

export default function PageConnexion() {

    //Création des constantes de la page connexion
    const [state] = useState({
      meta: {
        title: 'Gestion Emotions | Connexion',
        description: 'Page de connexion du site web de gestion de l\'application Have Fun.',
        canonical: 'https://gestion.havefunquebec.com/connexion'
      }
    });
    const navigate = useNavigate()
    const location = useLocation();

    useEffect(() => {
      verifier_token(navigate, location)
    })

    //On créer le rendu de la page connexion
    return (
      <DocumentMeta {...state.meta}>
        <Container fluid>
          <Navigation></Navigation>
          <TitrePage titre_page="Connexion"></TitrePage>
          <FormulaireConnexion>
          </FormulaireConnexion>
          <PiedPage></PiedPage>
        </Container>
      </DocumentMeta>
    );
  }