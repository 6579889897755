import React, { useEffect } from 'react';
import { useState } from 'react';
import { Button, Card, Col, Dropdown, Form, Row } from 'react-bootstrap';
import { CONSTANTS } from '../../config/constants';
import { obtenir_toutes_les_images } from '../../services/imageService';
import { creerCleUnique } from '../../utilitaires/listUtil';
import FenetreImage from './fenetre-image';
import styles from './liste-image.module.css';
import ZoneDepotImage from './zone-depot-image';
import { obtenir_toutes_les_categories_images } from '../../services/categorieImageService';
import HFAlerte from '../commun/hf-alerte';
import { useParams } from 'react-router-dom';
import IconeChargement from '../commun/icone-chargement';
import { compareAsc } from 'date-fns';
import { parseISO } from 'date-fns';

export default function ListeImage() {

    const [state, setState] = useState({
        imageMin:0,
        listeImageEvenement:null,
        listeImageCategorie:null,
        choixCategorie:null,
        paramsDeactivated: true,
        nomImageRecherche:null
    })
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false)
    const handleOpen = () => setShow(true)
    const [imageActuel, setImageActuel] = useState(null)
    const params = useParams();
    const numberOfImageShowed = 12;

    useEffect(() => {
        !state.listeImageEvenement && obtenir_toutes_les_images(state, setState)
    }, [state])

    useEffect(() => {
        state.listeImageEvenement &&
        !state.listeImageCategorie && obtenir_toutes_les_categories_images(state, setState)
    }, [state])

    function supprimerImageListe(imageId)
    {
        if(state.listeImageEvenement.length === 13)
        {
            setState({
                ...state,
                imageMin:0,
                listeImageEvenement: state.listeImageEvenement.filter((image) => image.id !== imageId)
            })
        }
        else
        {
            setState({
                ...state,
                listeImageEvenement: state.listeImageEvenement.filter((image) => image.id !== imageId)
            })
        }
        handleClose()
    }

    function modifier_image_liste()
    {
        setState({
            ...state,
            listeImageEvenement: null
        })
    }

    return (
        <>
            <FenetreImage 
                show={show} 
                handleClose={handleClose} 
                imageActuel={imageActuel}
                modifier_image_liste={modifier_image_liste}
                supprimerImageListe={supprimerImageListe}
                listeImageCategorie={state.listeImageCategorie}
            />
            <ZoneDepotImage stateListeImage={state} setStateListeImage={setState}></ZoneDepotImage>
            <Row className={'text-center ' + styles.conteneur_bouton_selection_images}>
                <Col lg="1"></Col>
                <Col lg="2">
                    {
                        state.listeImageEvenement &&
                        <Button 
                            className={styles.bouton_page_image}
                            disabled={state.imageMin === 0}
                            onClick={
                                () => {
                                    setState({
                                        ...state,
                                        imageMin: state.imageMin - numberOfImageShowed
                                    })
                                }
                            }
                        >Page précèdente</Button>
                    }
                    
                </Col>
                <Col lg="6" className={styles.conteneur_filtre_image}>
                        {
                            state.listeImageEvenement &&
                            <Row>
                                <Col lg="1"></Col>
                                <Col lg="6">
                                    <Dropdown className={styles.dropdown_categorie}>
                                        <Dropdown.Toggle variant='primary' id="dropdown-basic">
                                            { 
                                                state.choixCategorie ? 
                                                state.choixCategorie.nom :
                                                params.categorie_slug ?
                                                state.listeImageCategorie &&
                                                state.listeImageCategorie.filter((categorie_image) => {
                                                    if(categorie_image.slug === params.categorie_slug) return true;
                                                    return false;
                                                })[0].nom 
                                                : "Catégorie d'images d'événement" }
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                            <Dropdown.Item
                                                onClick={() => {
                                                    setState({
                                                        ...state,
                                                        choixCategorie: null,
                                                        paramsDeactivated: false,
                                                    })
                                                }}
                                            >
                                                Toutes les images
                                            </Dropdown.Item>
                                            {
                                                state.listeImageCategorie &&
                                                state.listeImageCategorie.length > 0 &&
                                                state.listeImageCategorie.map((categorie_image) => {
                                                    return (
                                                        <Dropdown.Item
                                                            key={creerCleUnique()}
                                                            onClick={() => {
                                                                setState({
                                                                    ...state,
                                                                    paramsDeactivated: false,
                                                                    choixCategorie: categorie_image
                                                                })
                                                            }}
                                                        >
                                                            {categorie_image.nom}
                                                        </Dropdown.Item>
                                                    );
                                                })
                                            }
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </Col>
                                <Col lg="4">
                                    <Form.Control 
                                        className={styles.recherche_categorie} 
                                        type="text" 
                                        placeholder="Entrer le nom de l'image"
                                        onChange={(event) => {
                                            setState({
                                                ...state,
                                                nomImageRecherche: event.target.value,
                                                imageMin:0
                                            })
                                        }}
                                    />
                                </Col>
                                <Col lg="1"></Col>
                            </Row>
                        }
                </Col>
                <Col lg="2">
                    {
                        state.listeImageEvenement &&
                        <Button
                            className={styles.bouton_page_image}
                            disabled={
                                state.imageMin + numberOfImageShowed >= 
                                state.listeImageEvenement
                                .filter((imageEvenement) => {
                                    if(state.nomImageRecherche)
                                    {
                                        if(!imageEvenement.nom) return false;
                                        return imageEvenement.nom.toLowerCase().includes(state.nomImageRecherche.toLowerCase())
                                    }
                                    return true;
                                })
                                .filter((imageEvenement) => {
                                    if(state.choixCategorie)
                                    {
                                        let categorie = imageEvenement.categories.find((categorie) => {
                                            if(categorie.id === state.choixCategorie.id)
                                            {
                                                return categorie;
                                            }
                                            return null;
                                        })
                                        if(categorie) return true;
                                        return false;
                                    }
                                    return true;
                                })
                                .filter((imageEvenement) => {
                                    if(params.categorie_slug && state.paramsDeactivated)
                                    {
                                        let categorie = imageEvenement.categories.find((categorie) => {
                                            if(categorie.slug === params.categorie_slug)
                                            {
                                                return categorie;
                                            }
                                            return null;
                                        })
                                        if(categorie) return true;
                                        return false;
                                    }
                                    return true;
                                })
                                .length}
                            onClick={
                                () => {
                                    setState({
                                        ...state,
                                        imageMin: state.imageMin + numberOfImageShowed
                                    })
                                }
                            }
                        >Page suivante</Button>
                    }
                </Col>
                <Col lg="1"></Col>
            </Row>
            <Row>
                <Col lg="1"></Col>
                <Col lg="10">
                    <Row>
                    {
                        ((state.listeImageEvenement && state.nomImageRecherche) ||
                        (state.listeImageEvenement && state.choixCategorie)) &&
                            state.listeImageEvenement
                            .filter((imageEvenement) => {
                                if(state.nomImageRecherche)
                                {
                                    if(!imageEvenement.nom) return false;
                                    return imageEvenement.nom.toLowerCase().includes(state.nomImageRecherche.toLowerCase())
                                }
                                return true;
                            })
                            .filter((imageEvenement) => {
                                if(state.choixCategorie)
                                {
                                    let categorie = imageEvenement.categories.find((categorie) => {
                                        if(categorie.id === state.choixCategorie.id)
                                        {
                                            return categorie;
                                        }
                                        return null;
                                    })
                                    if(categorie) return true;
                                    return false;
                                }
                                return true;
                            })
                            .filter((imageEvenement) => {
                                if(params.categorie_slug && state.paramsDeactivated)
                                {
                                    let categorie = imageEvenement.categories.find((categorie) => {
                                        if(categorie.slug === params.categorie_slug)
                                        {
                                            return categorie;
                                        }
                                        return null;
                                    })
                                    if(categorie) return true;
                                    return false;
                                }
                                return true;
                            })
                            .length === 0 &&
                            <HFAlerte
                                type="warning"
                                texte="Aucune image associé à votre recherche."
                            />
                    }
                    {
                        state.listeImageEvenement &&
                        state.listeImageEvenement
                            .filter((imageEvenement) => {
                                if(state.nomImageRecherche)
                                {
                                    if(!imageEvenement.nom) return false;
                                    return imageEvenement.nom.toLowerCase().includes(state.nomImageRecherche.toLowerCase());
                                }
                                return true;
                            })
                            .filter((imageEvenement) => {
                                if(state.choixCategorie)
                                {
                                    let categorie = imageEvenement.categories.find((categorie) => {
                                        if(categorie.id === state.choixCategorie.id)
                                        {
                                            return categorie;
                                        }
                                        return null;
                                    })
                                    if(categorie) return true;
                                    return false;
                                }
                                return true;
                            })
                            .filter((imageEvenement) => {
                                if(params.categorie_slug && state.paramsDeactivated)
                                {
                                    let categorie = imageEvenement.categories.find((categorie) => {
                                        if(categorie.slug === params.categorie_slug)
                                        {
                                            return categorie;
                                        }
                                        return null;
                                    })
                                    if(categorie) return true;
                                    return false;
                                }
                                return true;
                            })
                            .sort((a,b) => {
                                return compareAsc(parseISO(b.date_creation), parseISO(a.date_creation));
                            })
                            .map((image, index) => {
                                if(index >= state.imageMin && index < state.imageMin + numberOfImageShowed)
                                {
                                    return (
                                        <Col key={creerCleUnique()} lg="2" xs="6">
                                            <Card
                                                className={styles.carte_bibliotheque}
                                                onClick={() => {
                                                    setImageActuel(image)
                                                    handleOpen()
                                                }}
                                            >
                                                <Card.Img 
                                                    variant="top" 
                                                    className={styles.image_bibliotheque}
                                                    src={CONSTANTS.nomUrlImageApi + image.fichier_image} 
                                                    alt={image.description}
                                                    loading="lazy"
                                                />
                                                <Card.Body>
                                                    <Card.Title style={{color:"white"}}>{image.nom ?? "Aucun nom associé"}</Card.Title>
                                                    <Card.Text>{image.description ?? "Aucune description associé"}</Card.Text>
                                                </Card.Body>
                                            </Card>
                                        </Col>
                                    );
                                }
                                return false;
                            })
                    }
                    {
                        state.listeImageEvenement && state.listeImageEvenement.length === 0 &&
                        <Row>
                            <Col lg="4"/>
                            <Col lg="4">
                                <HFAlerte
                                    aucunRow={true}
                                    type="warning"
                                    texte="Aucune image n'a été trouvé dans la bibliothèque !"
                                />
                            </Col>
                            <Col lg="4"/>
                        </Row>
                    }
                    {
                        !state.listeImageEvenement &&
                        <Row>
                            <Col lg="3"/>
                            <Col lg="6">
                                <IconeChargement />
                            </Col>
                            <Col lg="3"/>
                        </Row>
                    }
                    </Row>
                </Col>
                <Col lg="1"></Col>
            </Row>
        </>
    );
  }