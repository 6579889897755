import React, { useState } from 'react'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import styles from './form-ajouter-un-utilisateur.module.css';
import HFAlerte from '../commun/hf-alerte';
import { ajouter_un_utilisateur } from '../../services/utilisateurService';

export default function FormulaireAjouterUnUtilisateur() {

    const [state, setState] = useState({
        erreurAjoutUtilisateur: {
            type: "",
            texte:""
        },
        nouvelUtilisateur: {
            prenom: "",
            nom: "",
            courriel: "",
            date_naissance:"",
            code_postal: "",
            mot_de_passe: "",
            est_actif: false,
            est_inscrit_infolettre: false,
            role_id: ""
        }
    })

    return (
        <>
            {
                state.erreurAjoutUtilisateur.texte && <HFAlerte type={state.erreurAjoutUtilisateur.type} texte={state.erreurAjoutUtilisateur.texte}/>
            }
            <Row className={styles.conteneur_form_connexion}>
                <Col lg="4" xs="1"></Col>
                <Col lg="4" xs="10" className="text-center">
                    <Form 
                        onSubmit={(event) => {
                            event.preventDefault();
                            ajouter_un_utilisateur(state, setState)
                        }} 
                        noValidate
                    >
                        <Form.Group className="mb-3" controlId="hf_courriel">
                            <Form.Label>Prénom de l'utilisateur*</Form.Label>
                            <Form.Control 
                                onChange={(event) => { 
                                    setState({
                                        ...state,
                                        nouvelUtilisateur: {
                                            ...state.nouvelUtilisateur,
                                            prenom: event.target.value
                                        }
                                    })
                                }} 
                                type="text" 
                                placeholder="John" 
                                autoFocus={true}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="hf_courriel">
                            <Form.Label>Nom de l'utilisateur*</Form.Label>
                            <Form.Control 
                                onChange={(event) => { 
                                    setState({
                                        ...state,
                                        nouvelUtilisateur: {
                                            ...state.nouvelUtilisateur,
                                            nom: event.target.value
                                        }
                                    })
                                }} 
                                type="text" 
                                placeholder="Doe"
                            />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="hf_courriel">
                            <Form.Label>Courriel de l'utilisateur*</Form.Label>
                            <Form.Control 
                                onChange={(event) => { 
                                    setState({
                                        ...state,
                                        nouvelUtilisateur: {
                                            ...state.nouvelUtilisateur,
                                            courriel: event.target.value
                                        }
                                    })
                                }} 
                                type="email" 
                                placeholder="exemple@havefunquebec.com"
                            />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="hf_mot_de_passe">
                            <Form.Label>Mot de passe de l'utilisateur*</Form.Label>
                            <Form.Control 
                                onChange={(event) => {  
                                    setState({
                                        ...state,
                                        nouvelUtilisateur: {
                                            ...state.nouvelUtilisateur,
                                            mot_de_passe: event.target.value
                                        }
                                    })
                                }} 
                                type="password" 
                                placeholder="exemple@#!motPAsse" 
                            />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="hf_courriel">
                            <Form.Label>Date de naissance*</Form.Label>
                            <Form.Control 
                                onChange={(event) => { 
                                    setState({
                                        ...state,
                                        nouvelUtilisateur: {
                                            ...state.nouvelUtilisateur,
                                            date_naissance: event.target.value
                                        }
                                    })
                                }} 
                                type="date" 
                                placeholder="1996-06-10"
                            />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="hf_courriel">
                            <Form.Label>Code postal*</Form.Label>
                            <Form.Control 
                                onChange={(event) => { 
                                    setState({
                                        ...state,
                                        nouvelUtilisateur: {
                                            ...state.nouvelUtilisateur,
                                            code_postal: event.target.value
                                        }
                                    })
                                }} 
                                type="text" 
                                placeholder="H1W 1V8"
                            />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="hf_courriel">
                            <Form.Label>Type de l'utilisateur*</Form.Label>
                            <Form.Select 
                                onChange={(event) => { 
                                    setState({
                                        ...state,
                                        nouvelUtilisateur: {
                                            ...state.nouvelUtilisateur,
                                            role_id: event.target.value
                                        }
                                    })
                                }}
                            >
                                <option value="">Sélectionner un rôle</option>
                                <option value="2">Administrateur</option>
                                <option value="3">Consommateur</option>
                                <option value="4">Testeur</option>
                                <option value="5">Annonceur</option>
                            </Form.Select>
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="hf_courriel">
                            <Form.Label>Réglages de l'utilisateur*</Form.Label>
                            <Form.Check
                                onClick={() => {
                                    setState({
                                        ...state,
                                        nouvelUtilisateur: {
                                            ...state.nouvelUtilisateur,
                                            est_actif: !state.nouvelUtilisateur.est_actif
                                        }
                                    })
                                }}
                                type="checkbox"
                                value={state.nouvelUtilisateur.est_actif}
                                selected={true}
                                label={"Est actif"}
                            />
                            <Form.Check
                                onClick={() => {
                                    setState({
                                        ...state,
                                        nouvelUtilisateur: {
                                            ...state.nouvelUtilisateur,
                                            est_inscrit_infolettre: !state.nouvelUtilisateur.est_inscrit_infolettre
                                        }
                                    })
                                }}
                                type="checkbox"
                                value={state.nouvelUtilisateur.est_inscrit_infolettre}
                                selected={true}
                                label={"Est inscrit à l'infolettre"}
                            />
                        </Form.Group>
                        <Button variant="primary" type="submit">
                            Ajouter un utilisateur
                        </Button>
                    </Form>
                </Col>
                <Col lg="4" xs="1"></Col>
            </Row>
        </>
    );
  }