import { CONSTANTS } from "../config/constants";
import validator from 'validator';
import { obtenirValeur } from "../utilitaires/localStorageUtil";
import axios from "axios";

export function obtenir_toutes_les_categories_evenement(state, setState)
{
    const token = obtenirValeur(CONSTANTS.nomValeurToken);
    axios.get(
      CONSTANTS.nomDomaineApi + "/categories/evenement", {
      headers: {
        'Content-Type': 'application/json',
        'Authorization':'Bearer ' + token
      }
    })
      .then(
        (response) => {
          if(response.status === 200)
          {
            setState({
                ...state,
                listeCategories: response.data.contenu,
            })
            return;
          }
          setState({
              ...state,
              listeCategories: []
          })
        }
      )
      .catch((error) => {
        console.log(error)
          setState({
              ...state,
              listeCategories: []
          })
      })
}

export function obtenir_categorie_evenement_par_slug(state, setState, categorie_slug)
{
  const token = obtenirValeur(CONSTANTS.nomValeurToken);
  axios.get(
    CONSTANTS.nomDomaineApi + "/categories/evenement/" + categorie_slug, 
    {
      headers: {
        'Content-Type': 'application/json',
        'Authorization':'Bearer ' + token
      }
    }
  )
    .then(
      (response) => {
        if(response.status === 200)
        {
          setState({
              ...state,
              categorie: response.data.contenu
          })
          return;
        }
        setState({
          ...state,
          categorie: [],
          alertFormModificationEvenement: {
            type: "danger",
            texte: "Cette catégorie n'existe pas."
          }
      })
      }
    )
    .catch((error) => {
      console.log(error)
      setState({
          ...state,
          categorie: [],
          alertFormModificationEvenement: {
            type: "danger",
            texte: "Une erreur est survenue lors du chargement de la catégorie."
          }
      })
    })
}

export function ajouter_categorie_evenement(state, setState)
{
  if(validator.isEmpty(state.categorie_evenement.nom))
  {
    setState({
      ...state,
      alertFormAjoutCategorie: {
        "type":"warning",
        "texte":"Veuillez remplir le champ 'nom' de la catégorie d'événement !"
      }
    })
    return;
  }
  if(validator.isEmpty(state.categorie_evenement.description))
  {
    setState({
      ...state,
      alertFormAjoutCategorie: {
        "type":"warning",
        "texte":"Veuillez remplir le champ 'description' de la catégorie d'événement !"
      }
    })
    return;
  }

  const token = obtenirValeur(CONSTANTS.nomValeurToken);
  axios.post(
    CONSTANTS.nomDomaineApi + "/ajouter/categorie/evenement", 
    state.categorie_evenement,
    {
    headers: {
      'Content-Type': 'application/json',
      'Authorization':'Bearer ' + token
    }
  })
    .then(
      (response) => {
        if(response.status === 200)
        {
          setState({
            categorie_evenement: {
              "nom":"",
              "description":""
            },
            alertFormAjoutCategorie: {
              "type":"success",
              "texte":"La catégorie d'événement a bel et bien été ajouté !"
            }
          })
          return;
        }
        setState({
          ...state,
          alertFormAjoutCategorie: {
            "type":"warning",
            "texte":"La catégorie d'événement n'a pas été ajouté, car celle-ci existe déjà !"
          }
        })
      }
    )
    .catch((error) => {
      console.log(error)
      setState({
        ...state,
        alertFormAjoutCategorie: {
          "type":"danger",
          "texte":"La catégorie d'événement n'a pas été ajouté !"
        }
      })
    })
}

export function modifier_categorie_evenement(state, setState)
{
  if(!state.categorie.nom)
  {
    setState({
      ...state,
      alertFormModificationCategorie: {
        "type":"warning",
        "texte":"Veuillez remplir le champ 'nom' de la catégorie d'événement !"
      }
    })
    return;
  }
  if(!state.categorie.description)
  {
    setState({
      ...state,
      alertFormModificationCategorie: {
        "type":"warning",
        "texte":"Veuillez remplir le champ 'description' de la catégorie d'événement !"
      }
    })
    return;
  }

  const token = obtenirValeur(CONSTANTS.nomValeurToken);
  axios.put(
    CONSTANTS.nomDomaineApi + "/modifier/categorie/evenement/" + state.categorie.id, 
    {
      nom: state.categorie.nom,
      description: state.categorie.description
    },
    {
      headers: {
        'Content-Type': 'application/json',
        'Authorization':'Bearer ' + token
      }
    }
  )
    .then(
      (response) => {
        if(response.status === 200)
        {
          setState({
            ...state,
            alertFormModificationCategorie: {
              "type":"success",
              "texte":"La catégorie d'événement a bel et bien été modifié !"
            }
          })
          return;
        }
        setState({
          ...state,
          alertFormModificationCategorie: {
            "type":"warning",
            "texte":"La catégorie d'événement n'a pas été modifié, car celle-ci existe déjà !"
          }
        })
      }
    )
    .catch((error) => {
        console.log(error)
        setState({
          ...state,
          alertFormModificationCategorie: {
            "type":"danger",
            "texte":"La catégorie d'événement n'a pas été modifié !"
          }
        })
    })
}

export function supprimer_categorie_evenement(state, setState, categorie_id)
{
  const token = obtenirValeur(CONSTANTS.nomValeurToken);
  axios.delete(
    CONSTANTS.nomDomaineApi + "/supprimer/categorie/evenement/" + categorie_id, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization':'Bearer ' + token
    }
  })
    .then(
      (response) => {
        if(response.status === 200)
        {
          setState({
            ...state,
            listeCategories: null
          })
        }
      }
    )
    .catch((error) => {
      console.log(error)
    })
}