import React, { useRef } from 'react';
import { useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { ajouter_image } from '../../services/imageService';
import styles from './zone-depot-image.module.css';
import HFAlerte from '../commun/hf-alerte';

export default function ZoneDepotImage(props) {
    const [state, setState] = useState({
        isHovering: false,
        alertStatusImage: {
            type:"",
            texte:""
        }
    })
    const refInput = useRef(null)

    function gererFichiers(fichiers, e) {
        ([...fichiers]).forEach((fichier) => {
            ajouter_image(fichier, props.stateListeImage, props.setStateListeImage, state, setState)
        })
        e.target.value = '';
    }

    return (
        <Row className='text-center'>
            <Col xs={1}></Col>
            <Col xs={10}>
                <div
                    className={!state.isHovering ? styles.zone_depot_image : styles.zone_depot_image_couleur}
                    onDrop={(e) => {
                        e.preventDefault()
                        e.stopPropagation()
                        setState({
                            ...state,
                            isHovering: false
                        })
                        let dt = e.dataTransfer
                        let files = dt.files
                        gererFichiers(files)
                    }}
                    onDragOver={(e) => {
                        e.preventDefault()
                        e.stopPropagation()
                        setState({
                            ...state,
                            isHovering: true
                        })
                    }}
                    onDragLeave={(e) => {
                        e.preventDefault()
                        e.stopPropagation()
                        setState({
                            ...state,
                            isHovering: false
                        })
                    }}
                    onDragEnter={(e) => {
                        e.preventDefault()
                        e.stopPropagation()
                        setState({
                            ...state,
                            isHovering: true
                        })
                    }}
                >
                    <form 
                        onClick={
                            () => {
                                refInput.current.click()
                            }
                        } 
                        onChange={(e) =>{
                            e.preventDefault()
                            gererFichiers(e.target.files, e)
                        }
                    } className="my-form">
                        <input ref={refInput} type="file" className={styles.file_element} multiple accept=".png,.jpg,.jpeg"/>
                        <p className={styles.texte_depot_image}><i>Déposez</i> une ou plusieurs images.</p> 
                        <p className={styles.texte_depot_image}><i>Cliquer</i> pour l'explorateur de fichier.</p>
                    </form>
                </div>
                {
                    state.alertStatusImage &&
                    state.alertStatusImage.type &&
                    <HFAlerte
                        aucunRow={true}
                        type={state.alertStatusImage.type}
                        texte={state.alertStatusImage.texte}
                    />
                }
            </Col>
            <Col xs={1}></Col>
        </Row>
    );
  }