import React, { useEffect, useState } from 'react'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Badge, Button, Card, Form, FormGroup, Table } from 'react-bootstrap';
import { obtenir_tous_les_evenements, supprimerEvenement } from '../../services/evenementService';
import styles from './liste-evenement.module.css';
import { creerCleUnique } from '../../utilitaires/listUtil';
import HFAlerte from '../commun/hf-alerte';
import { useNavigate } from 'react-router-dom';
import { compareDesc } from 'date-fns';

function genererListeEvenement(state, setState, width) {
    
  if(width <= 950)
  {
    return (
      state.listeEvenement
      .filter((evenement) => {
        if(state.texteRecherche)
        {
          if(evenement.id && ("#" + evenement.id).toLowerCase().includes(state.texteRecherche.toLowerCase())) return true;
          if(evenement.nom && evenement.nom.toLowerCase().includes(state.texteRecherche.toLowerCase())) return true;
          if(evenement.date_depart && evenement.date_depart.toLowerCase().includes(state.texteRecherche.toLowerCase())) return true;
          if(evenement.date_fin && evenement.date_fin.toLowerCase().includes(state.texteRecherche.toLowerCase())) return true;
          return false;
        }
        return true;
      })
      .sort((evenementA, evenementB) => {
          return compareDesc(new Date(evenementA.date_creation), new Date(evenementB.date_creation))
      })
      .slice(state.indexEvenement, state.indexEvenement + 10)
      .map((evenement) => {
        return (
          <Card className={styles.conteneur_liste_evenement}>
            <Card.Title className={styles.titre_carte_categorie_evenement}>{evenement.nom}</Card.Title>
            <a href={"/voir-evenement/" + evenement.id}><Badge className={styles.hf_bouton_liste} bg="primary">Voir l'événement</Badge></a>
            <a href={"/modifier-evenement/" + evenement.id}><Badge className={styles.hf_bouton_liste} bg="primary">Modifier l'événement</Badge></a>
            <Badge className={styles.hf_bouton_liste} onClick={(event) => { supprimerEvenement(event, evenement.id, state, setState) }} bg="primary">Supprimer l'événement</Badge>
          </Card>
        )
      })
    )
  }

  return (
    <Table className={styles.conteneur_liste_evenement} variant="dark" striped bordered hover size="sm" responsive="sm">
      <thead>
        <tr>
          <th>#</th>
          <th>Nom de l'événement</th>
          <th>Date/heure de début</th>
          <th>Date/heure de fin</th>
          <th>Catégories</th>
          <th>Actions</th>
        </tr>
      </thead>
      <tbody>
        {
          state.listeEvenement
          .filter((evenement) => {
            if(state.texteRecherche)
            {
              if(evenement.id && ("#" + evenement.id).toLowerCase().includes(state.texteRecherche.toLowerCase())) return true;
              if(evenement.nom && evenement.nom.toLowerCase().includes(state.texteRecherche.toLowerCase())) return true;
              if(evenement.date_depart && evenement.date_depart.toLowerCase().includes(state.texteRecherche.toLowerCase())) return true;
              if(evenement.date_fin && evenement.date_fin.toLowerCase().includes(state.texteRecherche.toLowerCase())) return true;
              return false;
            }
            return true;
          })
          .sort((evenementA, evenementB) => {
              return compareDesc(new Date(evenementA.date_creation), new Date(evenementB.date_creation))
          })
          .slice(state.indexEvenement, state.indexEvenement + 10)
          .map((evenement) => {
            return (
              <tr key={creerCleUnique()}>
                <td>{evenement.id}</td>
                <td>{evenement.nom}</td>
                <td>{evenement.date_depart}</td>
                <td>{evenement.date_fin || "N/D"}</td>
                <td>
                  {
                    evenement.categories && evenement.categories.map((categorie) => {
                      return (
                        <a
                          key={creerCleUnique()}
                          href={"/voir-tous-les-evenement/categorie/" + categorie.slug} 
                        >
                          <Badge className={styles.hf_bouton_liste} bg="primary">{categorie.nom}</Badge>
                        </a>
                      )
                    })
                  }
                  {
                    !evenement.categories.length && "Aucune catégorie associé"
                  }
                </td>
                <td>
                  <a href={"/voir-evenement/" + evenement.id}><Badge className={styles.hf_bouton_liste} bg="primary">Voir l'événement</Badge></a>
                  <a href={"/modifier-evenement/" + evenement.id}><Badge className={styles.hf_bouton_liste} bg="primary">Modifier l'événement</Badge></a>
                  <Badge className={styles.hf_bouton_liste} onClick={(event) => { supprimerEvenement(event, evenement.id, state, setState) }} bg="primary">Supprimer l'événement</Badge>
                </td>
              </tr>
            )
          })
        }
      </tbody>
    </Table>
  );
}

export default function ListeEvenement() {
  
  const [state, setState] = useState({
    listeEvenement: null,
    texteRecherche: "",
    indexEvenement: 0
  })
  const width = window.innerWidth;
  const navigate = useNavigate();

  useEffect(() => {
    !state.listeEvenement && obtenir_tous_les_evenements(state, setState); 
  }, [state])
  
  return (
    <>
      <Row className='text-center'>
        <Col lg="4"></Col>
        <Col lg="4">
          <Button
            onClick={() => {
              navigate("/ajouter-evenement")
            }}
            className={styles.bouton_ajout_evenement}
          >Ajouter événement</Button>
        </Col>
        <Col lg="4"></Col>
      </Row>
      <Row className={'text-center ' + styles.conteneur_bouton_pagination}>
          <Col lg="1"></Col>
          <Col lg="3" xs="12">
            <Button
              disabled={state.listeEvenement && state.indexEvenement <= 0}
              onClick={() => {
                if(state.listeEvenement && state.indexEvenement > 0){
                  setState({
                    ...state,
                    indexEvenement: state.indexEvenement - 10
                  })
                }
              }}
            >Précédent</Button>
          </Col>
          <Col className='d-lg-none' xs="2"></Col>
          <Col lg="4" xs="8" className="text-center">
              <Form
                onSubmit={(event) => {
                  event.preventDefault();
                }}
              >
                <FormGroup>
                  <input 
                    className='form-control'
                    type='text'
                    placeholder='Rechercher un événement'
                    value={state.texteRecherche}
                    onChange={(event) => {
                      setState({
                        ...state,
                        texteRecherche: event.target.value
                      })
                    }}
                  />
                </FormGroup>
              </Form>
          </Col>
          <Col className='d-lg-none' xs="2"></Col>
          <Col lg="3" xs="12">
            <Button
              disabled={state.listeEvenement && state.indexEvenement > state.listeEvenement.length - 10}
              onClick={() => {
                if(state.listeEvenement && state.indexEvenement < state.listeEvenement.length){
                  setState({
                    ...state,
                    indexEvenement: state.indexEvenement + 10
                  })
                }
              }}
            >Suivant</Button>
          </Col>
          <Col lg="1"></Col>
      </Row>
      <Row>
          <Col lg="1" xs="2"></Col>
          <Col lg="10" xs="8" className="text-center">
              {
                state.listeEvenement &&
                state.listeEvenement.length !== 0 && genererListeEvenement(state, setState, width)
              }
          </Col>
          <Col lg="1" xs="2"></Col>
      </Row>
      {
        state.listeEvenement &&
        state.listeEvenement.length === 0 &&
        <HFAlerte 
          type="warning"
          texte="Aucun événement à afficher."
        />
      }
    </>
  );
}