import { CONSTANTS } from "../config/constants";
import { obtenirValeur } from "../utilitaires/localStorageUtil";

export function obtenir_statistique_total_utilisateur(nomStatistique, state, setState)
{
    const token = obtenirValeur(CONSTANTS.nomValeurToken);
    fetch(CONSTANTS.nomDomaineApi + "/obtenir/statistique/" + nomStatistique.replace(/_/g, "-"), {
        headers: {
        'Content-Type': 'application/json',
        'Authorization':'Bearer ' + token
        },
        method: "GET",
        redirect: 'follow'
    })
        .then(
            async (response) => {

                let responseJSON = await response.json();
                if(response.status === 200)
                {
                    setState({
                        ...state,
                        [nomStatistique]: responseJSON.contenu.valeur
                    })
                    return;
                }
                setState({
                    ...state,
                    [nomStatistique]: 0
                })
            },
            (err) => {
                console.log(err)
                setState({
                    ...state,
                    [nomStatistique]:0
                })
            }
        )
}